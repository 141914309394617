<template>
  <div class="write-answer">
    <div class="left">
      <!-- 问题 -->
      <div class="question">
        <!-- 问题详情 -->
        <V-Question :questions="questions"></V-Question>
        <div class="q-btns">
          <!-- 我来答按钮 蓝色 -->
          <div class="answer-btn" v-if="Buttoms">
            <a href="javaScript:void(0)" @click="answerTheQuestion" class="block">
              <span class="write-icon"></span> 我来回答
            </a>
          </div>
          <!-- 灰色 -->
          <div class="answer-btn1" v-if="!Buttoms">
            <a href="javaScript:void(0)" @click="answerTheQuestions" class="block">
              <span class="write-icon"></span> 我来回答
            </a>
          </div>
          <span class="collect show-hand" @click="collectQuestion(WDCollect)">
            <!-- 收藏 -->
            <span v-if="WDCollect <= 0">
              <!-- <i class="collect-icon"  ></i> -->
              <i class="collect2-icon"></i>
              <span class="hover">收藏</span>
            </span>
            <span v-else-if="WDCollect > 0">
              <i class="collect1-icon"></i>
              <span class="hover">已收藏</span>
            </span>
          </span>
          <!-- 团队回答 -->
          <!-- <span class="share show-hand" @click="shareQuestion">
                        <i class="share-icon"></i>
                        <span>团队回答</span>
          </span>-->
          <el-tooltip content="邀请所在团队回答问题" placement="top" effect="light">
            <span class="share show-hand" @click="shareQuestion">
              <i class="share-icon"></i>
              <span>团队回答</span>
            </span>
          </el-tooltip>
          <el-popover placement="bottom" width="400" trigger="click" class="share">
            <div class="share">
              <ul>
                <!-- <li @click="share('WX')"><span>微信</span></li> -->
                <!-- <li @click="dealShare($event, 'weixin')">
                  <span><img  class="scoreS" src="@/assets/icon/20190506 WX.png" />微信</span>
                </li>-->
                <!-- 暂时还未开通 -->
                <li @click="share('WB')">
                  <span>
                    <img class="scoreS" src="@/assets/icon/WB0000.png" />微博
                  </span>
                </li>
                <li @click="share('qzone')">
                  <span>
                    <img class="scoreS" src="@/assets/icon/20190506 QQ.png" />QQ空间
                  </span>
                </li>
              </ul>
            </div>
            <span slot="reference" style=" cursor: pointer;">
              <img class="score" src="@/assets/icon/share2.png" />分享
            </span>
            <div id="qrcode" ref="qrcode"></div>
          </el-popover>
        </div>
        <div class="q-setting">
          <div class="setting">
            <div class="bottom-block">
              <div>
                <span>悬赏设置 :</span>
                <span class="condition-style">{{ rewardSetting }}</span>
              </div>
              <div>
                <span>回答方式 :</span>
                <span class="condition-style">{{ solveSetting }}</span>
              </div>
              <div>
                <span>查看分成 :</span>
                <span class="condition-style">{{ sharedSetting }}</span>
              </div>
              <div>
                <span>温馨提示 :</span>
                <span class="condition-style">（如果不能回答该问题，可点击团队回答邀请本人所在团队来回答问题）</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 写答案 -->
      <div class="answer" v-show="isAnswer">
        <!-- <V-EditorBar v-model="answer.answer" :isClear="isClear" @change="change"></V-EditorBar>  -->
        <textarea
          maxlength="5000"
          v-if="YinYvisable"
          v-model="answer.answer"
          id="search-icons"
          class="search-icon"
          @change="change"
          placeholder="写下你的回答："
        ></textarea>
        <textarea
          maxlength="5000"
          v-if="!YinYvisable"
          v-model="answer.answer"
          id="search-icons"
          class="search-icon"
          @change="change"
          placeholder="写下你的回答："
          disabled
        ></textarea>
        <div class="uppp">
          <!-- <div> 
                   <label class="up-file" for="upFile"><input type="file" style="display:none"  id="upFile" ref="file" @change="fileUpload" /></label>
                  <div style="margin-top:20px" v-for="(file,index) in upFiles" :key="index">
                      <span class="remove-file" @click="removeFile(index)">移除</span><span>{{file.name}}</span>
                  </div>

          </div>-->

          <!-- 上传图片 -->
          <el-tooltip class="item" effect="dark" content="添加图片" placement="top">
            <span class="picture" v-if="YinYvisable">
              <!-- <el-upload
                     :action="address"
                     accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG"
                     list-type="picture-card"
                     :on-preview="handlePictureCardPreview"
                     :on-remove="handleRemove"
                     :on-success="PICsuccess"
                     :limit="1"
                     >
                    <i class="up-img"></i>
                   </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                   <img width="100%" :src="imgDatas" alt="">
              </el-dialog>-->
              <el-upload
                class="Imege"
                :action="address"
                accept=".jpg, .jpeg, .png, .gif, .pdf, .JPG, .JPEG, .PBG, .GIF"
                :show-file-list="false"
                :before-upload="beforeUploadVideoIMG"
                :on-success="handleVideoSuccessIMG"
                :on-progress="uploadVideoProcessIMG"
              >
                <!-- <img
                      v-if="imgDatas !='' && videoFlagIMG == false"
                      :src="API+imgDatas"
                      width="300"
                      height="180"
                      controls="controls"
                      style="margin-right:20px"
                      class="putr"
                >-->

                <i class="up-img"></i>
              </el-upload>
              <!-- <el-progress
                      v-if="videoFlagIMG == true"
                      type="circle"
                      :percentage="videoUploadPercentIMG"
                      style="margin-top:30px"
                    ></el-progress>   
                  </el-upload>
              <span v-if="imgDatas !=''" class="detesed"><span @click="detes" class="detes"></span>   </span>-->
            </span>
          </el-tooltip>
          <!-- 上传视频 -->
          <el-tooltip class="item" effect="dark" content="添加视频" placement="top">
            <span class="video" v-if="YinYvisable">
              <el-upload
                class="Imege"
                :action="address"
                accept=".mp4, .MP4, .qlv, .qsv, .ogg, .flv, .avi, .wmv, .rmvb"
                :show-file-list="false"
                :before-upload="beforeUploadVideo"
                :on-success="handleVideoSuccess"
                :on-progress="uploadVideoProcess"
              >
                <i class="up-video"></i>
              </el-upload>
              <!-- <el-progress
                      v-if="videoFlag == true"
                      type="circle"
                      :percentage="videoUploadPercent"
                      style="margin-top:30px"
                    ></el-progress>   
                  </el-upload>
              <span v-if="videoDatas !=''" class="detesed"><span @click="dete" class="detes"></span>   </span>-->
              <!-- <el-upload
                     :action="address"
                     accept=".x-ms-wmv,.mp4,.Ogg,.baiMPEG4,.WebM"
                     list-type="picture-card"
                     :on-preview="handlevideoCardPreview"
                     :on-remove="handleRemovevido"
                     :on-success="videosuccess"
                     :limit="1"
                     >
                    <i  class="up-video"></i>
                     <video v-if="dialogVideoVisible "
                            v-bind:src="videoDatas"
                            class="avatar video-avatar"
                            controls="controls">
                        您的浏览器不支持视频播放
                    </video>
              </el-upload>-->
            </span>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="添加文件" placement="top">
            <span class="uplid" v-if="YinYvisable">
              <!-- ////上传附件 -->
              <el-upload
                class="uplides"
                :action="address"
                accept=".xls, .xlsx, .ppt, .pptx, .doc"
                :show-file-list="false"
                :before-upload="beforeUploadfile"
                :on-success="upliesuccess"
                :on-progress="uploadVideoProcessFile"
              >
                <i class="up-file"></i>
              </el-upload>
            </span>
          </el-tooltip>
          <!-- 引用 -->
          <span class="quote" @click="Quote()" v-if="YinYvisable">
            <el-tooltip class="item" effect="light" content="引用相似答案" placement="top-start">
              <el-button class="yinY"></el-button>
            </el-tooltip>
          </span>
          <!-- <span class="quote" @click="Dereference"  v-if="YinYvisable==false">
              <span class="yin1"></span>               
          </span>-->
          <span class="quote" @click="Dereference" v-if="YinYvisable == false">
            <el-tooltip class="item" effect="light" content="取消引用" placement="top-start">
              <el-button class="yin1"></el-button>
            </el-tooltip>
          </span>
        </div>
        <!--------------------- 上传后回显 ----------------------->
        <div class="Echo">
          <!-- 图片的进度条 -->
          <el-progress
            v-if="videoFlagIMG == true"
            type="circle"
            :percentage="videoUploadPercentIMG"
            style="margin-top:30px"
          ></el-progress>
          <!-- 上传图片显示 -->
          <span class="VUF" v-if="imgDatas != '' && videoFlagIMG == false">
            <img :src="API + imgDatas" controls="controls" class="putr" />

            <span v-if="imgDatas != '' && videoFlagIMG == false && YinYvisable" class="detesed">
              <span @click="detes" class="detes"></span>
            </span>
          </span>
          <!-- 视频的进度条                     -->
          <el-progress
            v-if="videoFlag == true"
            type="circle"
            :percentage="videoUploadPercent"
            style="margin-top:30px"
          ></el-progress>
          <!-- 上传视频显示 -->
          <span class="VUF" v-if="videoDatas != '' && videoFlag == false">
            <video :src="API + videoDatas" class="putr" controls="controls">您的浏览器不支持视频播放</video>

            <span v-if="videoDatas != '' && YinYvisable" class="detesed">
              <span @click="dete" class="detes"></span>
            </span>
          </span>
          <!-- 附件的进度条                    -->
          <el-progress
            v-if="videoFlagFile == true"
            type="circle"
            :percentage="videoUploadPercentFile"
            style="margin-top:30px"
          ></el-progress>
          <!-- 上传附件显示 -->
          <span class="VUF" v-if="FILEE != '' && videoFlagFile == false">
            <!-- @click="Seedd(upFiles)" -->
            <span style="color:blue;font-size:13px;margin-right:30px;vertical-align: top;">
              <span @click="Seedd(upFiles)">{{ FILEE }}</span>
              <span v-if="YinYvisable" style="cursor: pointer;" @click="detee" class="deteseds"></span>
            </span>
          </span>
        </div>
        <div>
          <div class="answer-btn" style="text-align:right;margin-top:10px">
            <a href="javaScript:void(0)" @click="saveInfo()" class="block">提交回答</a>
          </div>
        </div>
      </div>

      <!-- <热点内容详情 -->
      <div class="details">
        <!-- <div class="head"> -->
        <!-- 多个回答 -->
        <!-- <div class="time">{{comment}}个回答</div> -->
        <!-- </div> -->
        <V-Details
          :basiced="basiced"
          :userID="userID"
          @commenteds="commenteds"
          @sendChildComment="sendChildComment"
          @sendChildComments="sendChildComments"
          @deletesed="deletesed"
          @fabuloused="fabuloused"
          :CommentContent="CommentContent"
          :CommentComment="CommentComment"
          :comment="comment"
          :visabled="visabled"
          :sons="sons"
          :anniu="anniu"
        ></V-Details>
        <!-- 判断长度 -->
        <div @click="quanbu1" v-if="anniu">
          <!-- 如果是免费 -->
          <div class="open" v-if="this.shared.type == 2">
            继续阅读需
            <span style="color:red;font-weight: bold;">
              {{
              this.shared.pay
              }}
            </span>知识币
            <span class="down"></span>
          </div>
          <!-- 如果是收费 -->
          <div class="open" v-if="this.shared.type == 1">
            展开全部
            <span class="down"></span>
          </div>
        </div>
      </div>
    </div>
    <!-- 等待解答 -->
    <div class="right">
      <div class="wait-head">
        <span class="title">等待解答</span>
      </div>
      <V-WaitAnswer ref="child" :problem="problem" @seee="seee"></V-WaitAnswer>
      <div class="wait-head margin_top">
        <span class="title">问题推送</span>
      </div>
      <V-WaitAnswer ref="child" :problem="Problempush" @seee="seee"></V-WaitAnswer>
    </div>
    <div></div>
    <div></div>
    <!-- 引用 -->
    <div v-show="Quotes == true">
      <V-Quote ref="footer" @closed="closed" @closeYy="closeYy"></V-Quote>
    </div>
    <!-- 团队回答 -->
    <div v-show="Team == true">
      <V-Team @teamclose="teamclose" :Multiple="Multiple" :manyBidding="manyBidding"></V-Team>
    </div>
    <div v-if="loading">
      <V-Loading></V-Loading>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Question from "@/components/question/index.vue";
import EditorBar from "@/views/wisdom-answer/common/editor/index.vue";
import WaitAnswer from "@/views/wisdom-answer/common/wait-answer/index.vue";
import { DELETE, QUERYED, INSERT, UPDATE, QUERY } from "@/services/dao.js";
import {
  dataFormat,
  formatDate,
  integralAddTwo,
  showTimeFormat,
  AcceptNum,
  AcceptNumTeam
} from "@/js/common/index.js";
import Details from "@/views/wisdom-answer/answerDetail/hotspot-details/index.vue";
import { Alert, Slider } from "element-ui";
import Quote from "@/views/wisdom-answer/index/quote/index.vue";
import Team from "@/views/wisdom-answer/index/team-response/index.vue";
import Loading from "@/views/wisdom-answer/index/team-response/Pop/Loading/index.vue";
import {
  updatedownload,
  formatZero,
  getTimeFormat
} from "@/js/common/Resoures";

import ApolloClient from "apollo-client";
export default {
  data() {
    return {
      isjifen: "", //引用问题发布者
      centers: "", //中间转换
      sons: [], ///多人问答
      manyBidding: 0, //团队回答输入的竞价
      loading: false, //是否显示正在加载中
      QuoteProblem: 0, //引用问题ID
      QuoteAnswer: 0, //引用回答ID
      Quoteornot: 0, ///是否引用
      Multiple: [], //团队回答详情数组
      smilQuestionList: [], //相似问题的数组
      YinYvisable: true,
      YinYvisabled: true,
      problem: [], //等待解答
      Problempush: [], //问题推送
      isClear: false,
      detail: "",
      isAnswer: false,
      isAnswered: false,
      upFiles: [], //上传文件信息
      dialogVisibles: false,
      problemid: 0,
      questions: [],
      basiced: [], ///回答详情的数组
      WDCollect: 0, //收藏状态
      data: [],
      userID: 0, //获取当前登陆的id判断删除按钮是否显示
      img: "",
      imgDatas: [], //上传图片数组
      dialogVisible: false,
      videoDatas: [], //上传视频的数组
      dialogVideoVisible: false,
      comment: 0, //回答个数
      IA_inputw: "",
      comment_contents: "", //评论的内容
      commentid: "", //问题回答的ID,
      anser: 0,
      // fabuls:0,//显示点赞状态
      CommentContent: [], //评论显示的内容
      CommentComment: [], //评论的评论
      Quotes: false, ///引用是否显示
      Team: false, //团队回答是否显示
      num: [{ id: 0 }],
      limit: 1,
      Buttoms: false, ///按钮的显示
      anniu: false, ////显示按钮
      visabled: 0, //是否显示回答详情里面的
      address: "", //地址
      API: "",
      videoFlag: false, //刚开始的时候显示为flase(视频)
      videoFlagIMG: false, //刚开始的时候显示为flase（图片）
      videoFlagFile: false, //刚开始的时候显示为flase（附件）
      videoUploadPercentFile: "0%", //进度条刚开始的时候为0%(附件)
      videoUploadPercent: "0%", //进度条刚开始的时候为0%(视频)
      videoUploadPercentIMG: "0%", //进度条刚开始的时候为0%(图片)
      FILEE: "", //附件名称
      // 文本编辑器转换后的值
      // question:{
      //     id:-1,
      //     title:"算法正则化与拉格朗日乘子法有什么联系？",
      //     describe:"算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联算法正则化与拉格朗日乘子法有什么联有什么联算法正则化与拉格朗日乘子法有什么联",
      //     createTime:"1987-01-01:12:12:12",
      //     mediaList:[{
      //         url:"https://static.leiphone.com/uploads/new/article/pic/202006/5ede0bd7c178a.jpg?imageMogr2/thumbnail/!480x290r/gravity/Center/crop/480x290/quality/90",
      //         type:"video",
      //     }],

      // },
      shared: {
        type: 1, //分成 1-免费 2-分成
        pay: 0,
        answer: 0,
        release: 0
      },
      reward: {
        type: 1, //悬赏类型 1-免费 2-竞价成交 3-抢答成交 4-信用度成交 5选择成交
        pay: 1,
        endTime: "" //'2020-04-20 12:00:00'
      },
      solve: {
        type: 1,
        startTime: "", //'2020-04-20 12:00:00',
        endTime: "", //'2020-04-20 12:00:00',
        adress: ""
      },
      answer: {
        files: [], //上传文件返回的路径集合
        answer: "",
        completePay: 0
      }
    };
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化

      if (to.query.id != from.query.id) {
        this.problemid = this.$route.query.id;
        this.inits(); //重新加载数据
      }
    }
  },
  computed: {
    preShow: function() {
      let file = [];
      this.file.forEach((item, index) => {
        if (item.type == "video/mp4") {
          let url = URL.createObjectURL(item);
          file.push({ type: "video", video: url, name: item.name });
        }
        // if(item.type=="image/jpeg/png"){
        //   let url = URL.createObjectURL(item);
        //   file.push({type:"img",imge:url,name:item.name});
        // }
      });
      return file;
    },

    rewardSetting: function() {
      if (this.reward.type == 1) {
        return "免费";
      }
      if (this.reward.type == 2) {
        return (
          "竞价,价格不超过" +
          this.reward.pay +
          "知识币(截止时间:" +
          this.reward.endTime +
          ")"
        );
      }
      if (this.reward.type == 3) {
        return (
          "抢答,抢答成功将获得" +
          this.reward.pay +
          "知识币(截止时间:" +
          this.reward.endTime +
          ")"
        );
      }
      if (this.reward.type == 4) {
        return (
          "信用度,信用度高者回答获得" +
          this.reward.pay +
          "知识币(截止时间:" +
          this.reward.endTime +
          ")"
        );
      }
      if (this.reward.type == 5) {
        return (
          "提问者选择答案,被选中者将获得" +
          this.reward.pay +
          "知识币(截止时间:" +
          this.reward.endTime +
          ")"
        );
      }
      return "";
    },
    sharedSetting: function() {
      if (this.shared.type == 1) {
        return "免费";
      }
      if (this.shared.type == 2) {
        return (
          "查看问题付费" +
          this.shared.pay +
          "知识币" +
          "提问者的比例：" +
          this.shared.release +
          "%    " +
          "被采纳者的比例：" +
          this.shared.answer +
          "%"
        );
      }
      return "";
    },
    solveSetting: function() {
      if (this.solve.type == 1) {
        return "线上求解";
      }
      if (this.solve.type == 2) {
        if (
          this.solve.startTime == "NaN-0NaN-0NaN 0NaN:0NaN:0NaN" &&
          this.solve.endTime != "NaN-0NaN-0NaN 0NaN:0NaN:0NaN" &&
          this.solve.adress != "undefined"
        ) {
          return (
            "线下解答,至" + this.solve.endTime + ", 地点在" + this.solve.adress
          );
        } else if (
          this.solve.endTime == "NaN-0NaN-0NaN 0NaN:0NaN:0NaN" &&
          this.solve.startTime != "NaN-0NaN-0NaN 0NaN:0NaN:0NaN" &&
          this.solve.adress != "undefined"
        ) {
          return (
            "线下解答,求解时间从" +
            this.solve.startTime +
            ", 地点在" +
            this.solve.adress
          );
        } else if (
          this.solve.endTime == "NaN-0NaN-0NaN 0NaN:0NaN:0NaN" &&
          this.solve.startTime == "NaN-0NaN-0NaN 0NaN:0NaN:0NaN" &&
          this.solve.adress != "undefined"
        ) {
          return "线下解答,  地点在" + this.solve.adress;
        } else if (
          this.solve.endTime == "NaN-0NaN-0NaN 0NaN:0NaN:0NaN" &&
          this.solve.startTime != "NaN-0NaN-0NaN 0NaN:0NaN:0NaN" &&
          this.solve.adress == "undefined"
        ) {
          return "线下解答,求解时间从" + this.solve.startTime;
        } else if (
          this.solve.endTime != "NaN-0NaN-0NaN 0NaN:0NaN:0NaN" &&
          this.solve.startTime == "NaN-0NaN-0NaN 0NaN:0NaN:0NaN" &&
          this.solve.adress == "undefined"
        ) {
          return "线下解答,至" + this.solve.endTime;
        }
        return (
          "线下解答,求解时间从" +
          this.solve.startTime +
          "至" +
          this.solve.endTime +
          ", 地点在" +
          this.solve.adress
        );
      }
      return "";
    }
  },
  created() {
    //   var d = new Date('Thu May 12 2020 08:00:00 GMT+0800 (中国标准时间)');
    //  let  youWant=d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
    //alert(youWant)
    //alert( new Date('2020-11-30 09:35ss'))
    this.inits();
  },
  methods: {
    async seee(id) {
      this.$router.push({
        path: "/writeanswer",
        query: {
          id: id
        }
      });
      this.isAnswer = false;
    },
    // 关闭团队邀请
    async teamclose() {
      this.Team = false;
      ///刷新列表
      //判断是否展开
      if (this.anniu == true) {
        ////如果没展开
        this.anserNum(); ////显示回答个数
      } else {
        ////如果展开了
        if (this.comment == 0) {
          ///如果回答个数为0////未被采纳
          if (this.reward.type == 1) {
            //如果是免费（悬赏）
            this.answereds(); ////显示回答个数
            this.quanbu(); ///展开回答详情
            this.visabled = 1;
          } else {
            ///如果是收费（悬赏）
            if (showTimeFormat(new Date()) <= this.reward.endTime) {
              ////如果在时间内
              ///未采纳不显示
              ///如果在时间内
              this.comment = 0; //回答个数
              this.basiced = []; ////不展示
              this.visabled = 0;
            } else {
              ////如超过了时间
              this.endTime(this.reward.endTime);
              this.visabled = 1;
            }
            this.problrms(); //显示问题
          }
        } else {
          ///如果回答个数不为0
          if (this.reward.type == 1) {
            //如果是免费（悬赏）
            this.answereds(); ////显示回答个数
            this.quanbu(); ///展开回答详情
            this.visabled = 1;
          } else {
            ///如果是收费（悬赏）
            if (showTimeFormat(new Date()) <= this.reward.endTime) {
              ////如果在时间内
              //查询该问题是否被采纳
              let dopt = await QUERYED(
                "POST",
                "",
                "  WDUserQuestionAnswerRef(where: {wdquestionid: {_eq: " +
                  this.problemid +
                  "}, adopt: {_eq: 1}}) {  wdanswerref  }"
              );
              if (dopt.data.WDUserQuestionAnswerRef.length > 0) {
                ////如果被采纳
                this.quanbu(); //调用显示被采纳详情
                this.answereds(); //回答个数以及展开状态
                this.visabled = 1;
              } else {
                ///未采纳不显示
                ///如果在时间内
                this.comment = 0; //回答个数
                this.basiced = []; ////不展示
                this.visabled = 0;
              }
            } else {
              ////如超过了时间
              this.endTime(this.reward.endTime);
              this.visabled = 1;
            }
            this.problrms(); //显示问题
          }
        }
      }
      AcceptNum(this.user); //传递用户ID(修改用户)
      AcceptNumTeam(this.user); //传递用户ID（修改团队）
    },
    // 取消引用
    async Dereference() {
      this.answer.answer = ""; //清空文本
      this.imgDatas = ""; // 清空照片
      this.videoDatas = ""; // 清空视频
      this.FILEE = ""; // 清空附件
      this.upFiles = "";
      this.YinYvisable = true; //可编辑
      this.Quoteornot = 0; //（否）引用
      // 清空回答ID和问题ID
      this.QuoteProblem = 0; //问题ID
      this.QuoteAnswer = 0; ///回答ID
    },
    // 关闭引用
    async closed() {
      this.Quotes = false;
    },
    // 关闭引用事件
    async closeYy(id, IDS) {
      this.Quotes = false;
      this.$message({
        message: "引用会覆盖原有填写的回答,并且引用后的答案不可修改！",
        type: "success"
      });
      console.log("回答ID：" + id);
      // 根据回答ID查找回答内容
      let data = await QUERYED(
        "post",
        "",
        " WDUserQuestionAnswerRef(where:{wdanswerref: {_eq:" +
          id +
          "}}) { wdanswerref  videos   answer  imge answerFiles   createtimes questioner  }"
      );
      this.isjifen = data.data.WDUserQuestionAnswerRef[0].questioner; //引用问题发布者
      this.answer.answer = data.data.WDUserQuestionAnswerRef[0].answer; //显示回答内容
      this.imgDatas = data.data.WDUserQuestionAnswerRef[0].imge; //显示图片
      this.videoDatas = data.data.WDUserQuestionAnswerRef[0].videos; //显示视频
      this.upFiles = data.data.WDUserQuestionAnswerRef[0].answerFiles; //显示附件

      this.FILEE =
        data.data.WDUserQuestionAnswerRef[0].answerFiles == null
          ? ""
          : data.data.WDUserQuestionAnswerRef[0].answerFiles.substring(
              31,
              1000
            ); //显示附件名
      this.YinYvisable = false; //不可编辑
      this.Quoteornot = 1; //（是）引用
      //////存储引用的回答ID和问题ID
      this.QuoteProblem = IDS; //问题ID
      this.QuoteAnswer = id; ///回答ID
    },
    // 查看附件
    async Seedd(upFiles) {
      //  window.open(this.api.LoginURL.concat(upFiles),'_blank');///新建窗口
      window.location.href = this.api.LoginURL.concat(upFiles);
    },
    // 初始化
    async inits() {
      this.address = this.api.LoginURL.concat(
        "/resources/trading/resourcesUpload"
      );
      this.API = this.api.LoginURL.concat();
      ///显示问题
      this.problrms();
      //显示回答个数
      // this.answers();
      //显示评论回答的内容
      // this.a();
      if (this.$store.state.userinfo.id != undefined) {
        this.userID = this.$store.state.userinfo.id;
      }
      //显示收藏状态
      this.Collection();
      //显示回答问题
      this.answered();
      // this.thumbs();//点赞
      this.wait();
    },
    // 等待回答、、问题推送
    async wait() {
      this.problemid = this.$route.query.id;
      let info = await QUERYED(
        "post",
        "",
        "   WDQuestion(where: {answerNum: {_eq: 0}, _not: {id: {_eq: " +
          this.problemid +
          "}}}, limit: 10) {questionTitle id img  }"
      );
      this.problem.splice(0, this.problem.length);
      for (let i = 0; i < info.data.WDQuestion.length; i++) {
        this.problem.push({
          title: info.data.WDQuestion[i].questionTitle,
          img: info.data.WDQuestion[i].img,
          id: info.data.WDQuestion[i].id
        });
      }
      this.Problempush = [];
      ////问题推送
      axios({
        method: "post",
        url: this.api.LoginURL.concat("/common/similarQuestion"),
        params: {
          id: this.$route.query.id,
          raw: 10, //行数
          page: 1, //页数
          money: -1, //分成
          // competePay:-1,//竞价
          competePriceSetting: -1 //悬赏
        }
      })
        .then(response => {
          for (let i = 0; i < response.data.data.data.length; i++) {
            if (i < 10) {
              this.Problempush.push({
                title: response.data.data.data[i].questionTitle,
                img: "",
                id: response.data.data.data[i].id
              });
            }
          }
        })
        .catch(function(error) {
          console.log("错误" + error);
        });
    },
    // 删除图片
    handleRemove(file, fileList) {
      this.imgDatas = "";
      console.log(file, fileList);
    },
    ///////上传视频
    dete() {
      this.videoDatas = "";
    },
    detee() {
      this.FILEE = ""; //清空上传附件的显示
    },
    beforeUploadVideo(file) {
      //视频上传之前判断他的大小
      const isLt10M = file.size / 1024 / 1024 < 2000;
      if (!isLt10M) {
        this.$message.error("上传视频大小不能超过2000MB哦!");
        return false;
      }
    },
    beforeUploadfile(file) {
      //附件上传之前判断他的大小
      const isLt10M = file.size / 1024 / 1024 < 2000;
      if (!isLt10M) {
        this.$message.error("上传视频大小不能超过2000MB哦!");
        return false;
      }
    },
    uploadVideoProcess(event, file, fileList) {
      //视频上传的时候获取上传进度传给进度条
      this.videoFlag = true;
      this.videoUploadPercent = parseInt(file.percentage);
      console.log(this.videoUploadPercent);
    },
    uploadVideoProcessFile(event, file, fileList) {
      //附件上传的时候获取上传进度传给进度条
      this.videoFlagFile = true;
      this.videoUploadPercentFile = parseInt(file.percentage);
      console.log(this.videoUploadPercentFile);
    },
    handleVideoSuccess(file) {
      //视频上传成功之后返回视频地址
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      this.videoDatas = file.data.originalFileUrl;
      this.$message({
        message: "视频暂存成功！",
        type: "success"
      });
      console.log("上传视频成功" + file);
    },
    //////////////////上传图片
    detes() {
      this.imgDatas = "";
      console.log(this.imgDatas);
    },
    beforeUploadVideoIMG(file) {
      //图片上传之前判断他的大小
      const isLt10M = file.size / 1024 / 1024 < 2000;
      if (!isLt10M) {
        this.$message.error("上传图片大小不能超过2000MB哦!");
        return false;
      }
    },
    uploadVideoProcessIMG(event, file, fileList) {
      //图片上传的时候获取上传进度传给进度条
      this.videoFlagIMG = true;
      this.videoUploadPercentIMG = parseInt(file.percentage);
      console.log(this.videoUploadPercentIMG);
    },
    handleVideoSuccessIMG(file) {
      //图片上传成功之后返回视频地址
      this.videoFlagIMG = false;
      this.videoUploadPercentIMG = 0;
      this.imgDatas = file.data.originalFileUrl;
      this.$message({
        message: "图片暂存成功！",
        type: "success"
      });
      console.log("上传图片成功" + file);
    },
    //文件列表上传之前
    handleuplieCardPreview(file) {
      // this.upFiles = file.url;
      // this.dialogVisibles = true;
      const isLt10M = file.size / 1024 / 1024 < 4000;
      if (!isLt10M) {
        this.$message.error("上传附件大小不能超过4MB哦!");
        return false;
      }
    },
    // 文件列表上传文件时的钩子(附件)
    // 文件列表移除文件时的钩子(视频)
    handleRemovevido(file, fileList) {
      this.videoDatas = "";
      console.log(file, fileList);
    },
    //点击文件列表中已上传的文件时的钩子（图片）
    handlePictureCardPreview(file) {
      this.imgDatas = file.url;
      this.dialogVisible = true;
    },
    //点击文件列表中已上传的文件时的钩子（视频）
    handlevideoCardPreview(file) {
      this.videoDatas = file.url;
      this.dialogVideoVisible = true;
    },
    //上传图片成功后返回的数据（图片）
    PICsuccess(file) {
      this.imgDatas = file.data.url;
      if (this.imgDatas != "") {
        this.$message({
          message: "上传成功！",
          type: "success"
        });
        console.log("上传返回的图片路径：" + this.imgDatas);
      } else {
        this.$message({
          message: "上传失败！",
          type: "warning"
        });
      }
    },
    //上传视频后返回的数据(视频)
    videosuccess(file) {
      this.videoDatas = file.data.url;
      if (this.videoDatas != "") {
        this.$message({
          message: "上传成功！",
          type: "success"
        });
        console.log("上传返回的视频路径：" + this.videoDatas);
      } else {
        this.$message({
          message: "上传失败！",
          type: "warning"
        });
      }
    },
    //    上传附件返回的信息
    upliesuccess(file) {
      this.videoFlagFile = false;
      this.videoUploadPercentFile = 0;
      this.upFiles = file.data.originalFileUrl;
      // 显示上传附件名称
      this.FILEE = file.data.fileName;
      this.$message({
        message: "附件暂存成功！",
        type: "success"
      });
      console.log("上传附件成功" + file);
      //  console.log("附件"+file)
      //  this.upFiles=file.data.originalFileUrl;
      //  if(this.upFiles!='')
      //     {
      //              this.$message({
      //                       message: "上传成功！",
      //                        type: "success",
      //                      });
      //                      console.log(JSON.stringify(file))
      //     console.log('上传返回的附件路径：'+this.upFiles)
      //     }
      //     else{
      //                  this.$message({
      //                   message: "上传失败！",
      //                   type: "warning",
      //                 });
    },
    //删除回答
    async deletesed(id) {
      this.loading = true; //显示隐藏
      //回答表
      let totals = null;
      //获取问题ID
      this.problemid = this.$route.query.id;
      let user = this.$store.state.userinfo.id; //获取当前用户ID
      //回答表里面的回答数

      //通过回答的ID查询评论

      //直接删除回答表
      totals = await DELETE(
        "post",
        "",
        " delete_WdAnswerRef(where: {id: {_eq: " +
          id +
          "}}) {    affected_rows  }"
      );
      if (totals.data.delete_WdAnswerRef.affected_rows > 0) {
        //获取问题表里面回答数数量
        let datdse = await QUERYED(
          "post",
          "",
          "  WDQuestion(where: {id: {_eq: " +
            this.problemid +
            "}}) {  answerNum  }"
        );
        if (datdse.data.WDQuestion[0].answerNum == 0) {
          datdse.data.WDQuestion[0].answerNum = 0;
        } else {
          datdse.data.WDQuestion[0].answerNum -= 1;
        }
        //修改回答数量
        let datdeds = await UPDATE(
          "post",
          "",
          "   update_WdQuestion(where: {id: {_eq: " +
            this.problemid +
            "}}, _set: {answerNum: " +
            datdse.data.WDQuestion[0].answerNum +
            "}) { affected_rows  }"
        );
        if (datdeds.data.update_WdQuestion.affected_rows > 0) {
          //    ////查询评论表是否有数据
          let info1 = await QUERYED(
            "post",
            "",
            "  Comment(where: {whole: {_eq: " + id + "}}) {  id  }"
          );
          if (info1.data.Comment.length > 0) {
            ///删除评论表
            let totaled = await DELETE(
              "post",
              "",
              "   delete_Comment(where: {whole: {_eq: " +
                id +
                "}}) {     affected_rows }"
            );
            if (totaled.data.delete_Comment.affected_rows > 0) {
              this.$message({
                message: "删除成功！",
                type: "success"
              });
              this.Deleterefresh(); //刷新
              AcceptNum(user); //传递用户ID(修改用户)
              AcceptNumTeam(user); //传递用户ID（修改团队）
            }
          } else {
            this.$message({
              message: "删除成功！",
              type: "success"
            });
            this.Deleterefresh(); //刷新
            AcceptNum(user); //传递用户ID(修改用户)
            AcceptNumTeam(user); //传递用户ID（修改团队）
          }
        }
      } else {
        this.$message({
          message: "删除失败！",
          type: "warning"
        });
      }
      this.loading = false; //显示隐藏
      //  }

      //     let totals=  await  DELETE("post","",' delete_WdAnswerRef(where: {id: {_eq: '+id+'}}) {    affected_rows  }');
    },
    // 操作后刷新
    async Deleterefresh() {
      if (showTimeFormat(new Date()) <= this.reward.endTime) {
        //如果在时间内
        this.quanbu();
        this.answereds(); ////显示回答个数
      } else {
        //如果在时间外
        //查询该问题是否被采纳
        let dopt = await QUERYED(
          "POST",
          "",
          "  WDUserQuestionAnswerRef(where: {wdquestionid: {_eq: " +
            this.problemid +
            "}, adopt: {_eq: 1}}) {  wdanswerref  }"
        );
        if (dopt.data.WDUserQuestionAnswerRef.length > 0) {
          //如果被采纳
          this.quanbu();
          this.answereds(); ////显示回答个数
        } else {
          this.endTime(this.reward.endTime);
        }
      }
    },
    //        changeImage() {
    //   // 上传图片事件
    //   var files = this.$refs.avatarInput.files;
    //   var that = this;
    //   function readAndPreview(file) {
    //     //Make sure `file.name` matches our extensions criteria
    //     if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
    //       var reader = new FileReader();
    //       reader.onload = function(e) {
    //         // 防止重复上传
    //         if (that.imgDatas.indexOf(e.target.result) === -1) {
    //           that.imgDatas.push(e.target.result);

    //         }
    //       };
    //       reader.readAsDataURL(file);
    //     }
    //   }
    //   readAndPreview(files[0])
    //   if (files.length === 0) {
    //     return;
    //   }

    //   // 文件上传服务器
    //   // this.setUploadFile(files[0])

    // },
    //评论的评论           ///////////////////////////////二级评论////////////////////////
    async sendChildComment(parentId, content1, ID, index, ancestorId) {
      //////////////ID为回答问题的id
      //    //获取登陆用户ID
      this.user = this.$store.state.userinfo.id;
      //alert(basicid)
      //alert(wdcommentid)
      if (content1 == "" || content1 == null) {
        this.$message({
          message: "请输入评论",
          type: "warning"
        });
      } else {
        if (
          this.user == "" ||
          this.user == null ||
          this.user == "Undefined" ||
          this.user < 0
        ) {
          this.$message({
            type: "warning",
            message: "您还未登录，请您先登录！"
          });
        } else {
          let datas = await INSERT(
            "post",
            "",
            "   insert_Comment(objects: {whole: " +
              ID +
              ', type: 2, time: "' +
              new Date() +
              '", parentCommentId: ' +
              parentId +
              ', isShield: 0,  content: "' +
              content1 +
              '", commentator: ' +
              this.user +
              ", ancestorCommentId: " +
              ancestorId +
              ",answer:" +
              ID +
              "}) { affected_rows  }"
          );
          if (datas.data.insert_Comment.affected_rows > 0) {
            //获取评论数
            let data = await QUERYED(
              "post",
              "",
              "  WDUserQuestionAnswerRef(where: {wdanswerref: {_eq: " +
                ID +
                "}}) { answers }"
            );
            data.data.WDUserQuestionAnswerRef[0].answers += 1;
            //评论数+1
            //   anser+=1;
            //alert(data.data.WDUserQuestionAnswerRef[0].answers)
            ///修改评论数
            let dataed = await UPDATE(
              "post",
              "",
              "   update_WdAnswerRef(where: {id: {_eq: " +
                ID +
                "}}, _set: {answers: " +
                data.data.WDUserQuestionAnswerRef[0].answers +
                "}) {  affected_rows }"
            );
            if (dataed.data.update_WdAnswerRef.affected_rows > 0) {
              this.$message({
                message: "评论成功！",
                type: "success"
              });
              //清空文本输入框
              // let cearch=
              //评论成功关闭评论框
              this.Deleterefresh(); //刷新回答详情
            } else {
              this.$message({
                message: "评论失败！",
                type: "warning"
              });
            }
          } else {
            his.$message({
              message: "评论失败！",
              type: "warning"
            });
          }
        }
      }
    },
    //评论的评论           ///////////////////////////////三级级评论////////////////////////
    async sendChildComments(parentId, content2, ID, ancestorId, UserauthorId) {
      //////////////ID为回答问题的id
      //    //获取登陆用户ID
      this.user = this.$store.state.userinfo.id;
      //alert(basicid)
      //alert(wdcommentid)
      if (content2 == "" || content2 == null) {
        this.$message({
          message: "请输入评论",
          type: "warning"
        });
      } else {
        if (
          this.user == "" ||
          this.user == null ||
          this.user == "Undefined" ||
          this.user < 0
        ) {
          this.$message({
            type: "warning",
            message: "您还未登录，请您先登录！"
          });
        } else {
          ////添加评论
          let datas = await INSERT(
            "post",
            "",
            "   insert_Comment(objects: {whole: " +
              ID +
              ', type: 2, time: "' +
              new Date() +
              '", parentCommentId: ' +
              UserauthorId +
              ', isShield: 0,  content: "' +
              content2 +
              '", commentator: ' +
              this.user +
              ", ancestorCommentId: " +
              ancestorId +
              ",answer:" +
              ID +
              "}) { affected_rows  }"
          );
          if (datas.data.insert_Comment.affected_rows > 0) {
            //获取评论数
            let data = await QUERYED(
              "post",
              "",
              "  WDUserQuestionAnswerRef(where: {wdanswerref: {_eq: " +
                ID +
                "}}) { answers }"
            );
            data.data.WDUserQuestionAnswerRef[0].answers += 1;
            //评论数+1
            //   anser+=1;
            //alert(data.data.WDUserQuestionAnswerRef[0].answers)
            ///修改评论数
            let dataed = await UPDATE(
              "post",
              "",
              "   update_WdAnswerRef(where: {id: {_eq: " +
                ID +
                "}}, _set: {answers: " +
                data.data.WDUserQuestionAnswerRef[0].answers +
                "}) {  affected_rows }"
            );
            if (dataed.data.update_WdAnswerRef.affected_rows > 0) {
              this.$message({
                message: "评论成功！",
                type: "success"
              });
              //清空文本输入框
              // let cearch=
              //评论成功关闭评论框

              this.Deleterefresh(); //问题详情
            } else {
              this.$message({
                message: "评论失败！",
                type: "warning"
              });
            }
          } else {
            his.$message({
              message: "评论失败！",
              type: "warning"
            });
          }
        }
      }
    },
    // 写下评论              ///////////////一级评论///////////////////
    async commenteds(commentseds, ID, index) {
      /////ID为回答问题的ID
      //获取登陆用户ID
      this.user = this.$store.state.userinfo.id;
      //alert(this.comment_contents)
      if (commentseds == "" || commentseds == null) {
        this.$message({
          message: "请输入评论",
          type: "warning"
        });
      } else {
        if (
          this.user == "" ||
          this.user == null ||
          this.user == "Undefined" ||
          this.user < 0
        ) {
          this.$message({
            type: "warning",
            message: "您还未登录，请您先登录！"
          });
        } else {
          ///评论表加一条数据
          let datas = await INSERT(
            "post",
            "",
            "   insert_Comment(objects: {whole: " +
              ID +
              ', type: 2, time: "' +
              new Date() +
              '", parentCommentId: 0, isShield: 0,  content: "' +
              commentseds +
              '", commentator: ' +
              this.user +
              ", ancestorCommentId: 0,answer:" +
              ID +
              "}) { affected_rows  }"
          );
          if (datas.data.insert_Comment.affected_rows > 0) {
            //获取评论数
            let data = await QUERYED(
              "post",
              "",
              "  WDUserQuestionAnswerRef(where: {wdanswerref: {_eq: " +
                ID +
                "}}) { answers }"
            );
            this.anser = data.data.WDUserQuestionAnswerRef[0].answers;
            //评论数+1
            this.anser += 1;
            let dataed = await UPDATE(
              "post",
              "",
              "   update_WdAnswerRef(where: {id: {_eq: " +
                ID +
                "}}, _set: {answers: " +
                this.anser +
                "}) {  affected_rows }"
            );
            if (dataed.data.update_WdAnswerRef.affected_rows > 0) {
              this.$message({
                message: "评论成功！",
                type: "success"
              });
              //清空文本输入框
              // let cearch=
              //评论成功关闭评论框
              let sub_menu = this.$el.querySelectorAll(".texts");
              for (let j = 0, len = sub_menu.length; j < len; j++) {
                if (index == j) {
                  if (sub_menu[j].style.display == "none") {
                    sub_menu[j].style.display = "block";
                  } else {
                    sub_menu[j].style.display = "none";
                  }
                } else if (sub_menu[j].style.display == "block") {
                  sub_menu[j].style.display = "none";
                }
              }

              this.Deleterefresh(); //问题详情
            } else {
              this.$message({
                message: "评论失败！",
                type: "warning"
              });
            }
          } else {
            his.$message({
              message: "评论失败！",
              type: "warning"
            });
          }
        }
      }
    },
    ///显示收藏状态
    async Collection() {
      //获取问题的ID
      this.problemid = this.$route.query.id;
      //获取登陆用户ID
      let user = null;
      this.user = this.$store.state.userinfo.id;
      if (this.user != undefined) {
        //查询收藏的ID是否存在
        let totals = await QUERYED(
          "post",
          "",
          "    WDCollect(where: {user: {_eq: " +
            this.user +
            "}, question: {_eq: " +
            this.problemid +
            "}}) { id }"
        );
        //alert(totals.data.WDCollect.length)
        this.WDCollect = totals.data.WDCollect.length;
      } else {
        this.WDCollect = 0;
      }
    },
    upLoad() {
      // 触发上传图片按钮
      this.$refs.avatarInput.dispatchEvent(new MouseEvent("click"));
    },

    //邀请多人回答
    async shareQuestion() {
      ////打开多人问答
      // 判断是否有用户登陆
      if (
        this.$store.state.userinfo.id != null &&
        this.$store.state.userinfo.id != undefined
      ) {
        ////如果被抢答了
        if (this.Buttoms == false) {
          this.answerTheQuestions(); //调用灰色按钮事件
        } else {
          // 如果是信用度问题
          if (this.reward.type == 4) {
            this.$message({
              type: "warning",
              message: "暂不支持多人问答参与信用度问题！"
            });
          } else {
            // 判断用户是否加入了团队
            let TD = await QUERY(
              "POST",
              "",
              "WDTeamMember(where: {userid : {_eq: " +
                this.$store.state.userinfo.id +
                "}}) {id }"
            );
            if (TD.data.WDTeamMember.length == 0) {
              this.$message({
                type: "warning",
                message: "您还没有加入任何团队，请先加入团队！"
              });
            } else {
              this.answerTheQuestionTeam(); /////打开多人问答
            }
          }
        }
      } else {
        this.$message({
          type: "warning",
          message: "请您先登录！"
        });
      }
    },
    /////确定打开多人回答
    async TeamSure() {
      this.Team = true; //打开详情
      this.Multiple = []; //清空团队回答的列表
      // 根据登录ID查找该用户加入的团队ID
      let Team = await QUERY(
        "Post",
        "",
        "WDTeamMember(where: {userid: {_eq: " +
          this.$store.state.userinfo.id +
          "}}) {team }"
      );
      for (let i = 0; i < Team.data.WDTeamMember.length; i++) {
        console.log("团队ID：" + Team.data.WDTeamMember[i].team);
        //根据团队ID查找团队详情
        let Teams = await QUERY(
          "POST",
          "",
          "WDTeamByAccept(where: {id: {_eq: " +
            Team.data.WDTeamMember[i].team +
            "}}) {teamImg teamName teamType createTime teamIntro }"
        );
        let TZ = await QUERY(
          "POST",
          "",
          "WDTeamMember(where: {team: {_eq: " +
            Team.data.WDTeamMember[i].team +
            '},Leader:{_eq: "团长"}}) {name }'
        );
        for (let j = 0; j < Teams.data.WDTeamByAccept.length; j++) {
          this.Multiple.push({
            id: Team.data.WDTeamMember[i].team,
            name: Teams.data.WDTeamByAccept[j].teamName,
            img: this.api.LoginURL.concat(Teams.data.WDTeamByAccept[j].teamImg),
            type: Teams.data.WDTeamByAccept[j].teamType,
            leader: TZ.data.WDTeamMember[j].name,
            creatime: showTimeFormat(Teams.data.WDTeamByAccept[j].createTime)
          });
        }
      }
    },
    /////多人问答的的弹出框
    async answerTheQuestionTeam() {
      //如果是免费
      if (this.reward.type == 1) {
        this.TeamSure();
        return;
      } else {
        if (showTimeFormat(new Date()) <= this.reward.endTime) {
          // if(this.isAnswer){
          //     return;
          // }
          let rewardPay = this.reward.pay;
          if (this.reward.type == 2) {
            //  判断该问题有没有被采纳
            //  查询是否被采纳
            let dopt = await QUERYED(
              "POST",
              "",
              "  WDUserQuestionAnswerRef(where: {wdquestionid: {_eq: " +
                this.problemid +
                "}, adopt: {_eq: 1}}) {  wdanswerref  }"
            );
            if (dopt.data.WDUserQuestionAnswerRef.length > 0) {
              ///如果被采纳
              //    this.$alert('已经有人被采纳了哦！', '系统提示', {
              //         dangerouslyUseHTMLString: true,
              //         showClose:false,
              //         distinguishCancelAndClose:true
              // });
              //  this.$message({
              //                 type: 'success',
              //                 message: '已经有人被采纳了哦!'
              //             });
              //  this.TeamSure();
              this.$alert("已经有人被采纳了哦！", "系统提示", {
                confirmButtonText: "确定",
                showClose: false,
                distinguishCancelAndClose: true,
                callback: action => {
                  this.TeamSure();
                }
              });
            } else {
              this.$prompt(
                "参与竞价,竞价金额不能超过" + this.reward.pay,
                "竞价",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  closeOnClickModal: false,
                  inputPattern: /^\d+$/,
                  inputErrorMessage: "竞价金额为不大于" + rewardPay + "的整数",
                  inputValidator: function(value) {
                    if (value <= rewardPay) {
                      return true;
                    }
                    return false;
                  }
                }
              )
                .then(({ value }) => {
                  // this.TeamSure();
                  this.manyBidding = Number(value);

                  this.TeamSure();
                  this.$message({
                    type: "success",
                    message:
                      "您的竞价金额是: " + value + "知识币,请邀请你的团队."
                  });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "取消输入"
                  });
                });
              return;
            }
          }
          if (this.reward.type == 3) {
            //  判断该问题有没有被采纳
            //  查询是否被采纳
            let dopt = await QUERYED(
              "POST",
              "",
              "  WDUserQuestionAnswerRef(where: {wdquestionid: {_eq: " +
                this.problemid +
                "}, adopt: {_eq: 1}}) {  wdanswerref  }"
            );
            if (dopt.data.WDUserQuestionAnswerRef.length > 0) {
              ///如果被采纳
              //    this.$alert('问题已经被采纳，继续回答将不会获得知识币！', '系统提示', {
              //         dangerouslyUseHTMLString: true,
              //         showClose:false,
              //         distinguishCancelAndClose:true
              // });
              this.$alert(
                "问题已经被采纳，继续回答将不会获得知识币！",
                "系统提示",
                {
                  confirmButtonText: "确定",
                  showClose: false,
                  distinguishCancelAndClose: true,
                  callback: action => {
                    this.TeamSure();
                  }
                }
              );
              //          this.$message({
              //                 type: 'success',
              //                 message: '问题已经被采纳，继续回答将不会获得知识币！'
              //             });
              //  this.TeamSure();
            } else {
              this.$confirm(
                "抢答被采纳后将获得" + this.reward.pay + "知识币",
                "抢答",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }
              )
                .then(() => {
                  this.TeamSure();
                  this.$message({
                    type: "success",
                    message: "参与抢答成功,请邀请你的团队."
                  });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "取消抢答"
                  });
                });
              return;
            }
          }
          if (this.reward.type == 4) {
            //  判断该问题有没有被采纳
            //  查询是否被采纳
            let dopt = await QUERYED(
              "POST",
              "",
              "  WDUserQuestionAnswerRef(where: {wdquestionid: {_eq: " +
                this.problemid +
                "}, adopt: {_eq: 1}}) {  wdanswerref  }"
            );
            if (dopt.data.WDUserQuestionAnswerRef.length > 0) {
              ///如果被采纳
              //    this.$alert('已经有人被采纳了哦！', '系统提示', {
              //         dangerouslyUseHTMLString: true,
              //         showClose:false,
              //         distinguishCancelAndClose:true
              // });
              // this.$message({
              //                 type: 'success',
              //                 message: '已经有人被采纳了哦！'
              //             });
              //  this.TeamSure();
              this.$alert("已经有人被采纳了哦！", "系统提示", {
                confirmButtonText: "确定",
                showClose: false,
                distinguishCancelAndClose: true,
                callback: action => {
                  this.TeamSure();
                }
              });
            } else {
              this.$confirm(
                "回答被采纳后将获得" + this.reward.pay + "知识币",
                "信用度",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }
              )
                .then(() => {
                  this.TeamSure();
                  this.$message({
                    type: "success",
                    message: "参与信用度回答成功,请邀请你的团队."
                  });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "取消回答"
                  });
                });
              return;
            }
          }
          if (this.reward.type == 5) {
            //  判断该问题有没有被采纳
            //  查询是否被采纳
            let dopt = await QUERYED(
              "POST",
              "",
              "  WDUserQuestionAnswerRef(where: {wdquestionid: {_eq: " +
                this.problemid +
                "}, adopt: {_eq: 1}}) {  wdanswerref  }"
            );
            if (dopt.data.WDUserQuestionAnswerRef.length > 0) {
              ///如果被采纳
              this.$alert("已经有人被采纳了哦！", "系统提示", {
                confirmButtonText: "确定",
                showClose: false,
                distinguishCancelAndClose: true,
                callback: action => {
                  this.TeamSure();
                }
              });
            } else {
              this.$confirm(
                "回答被采纳后将获得" + this.reward.pay + "知识币",
                "选择成交",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }
              )
                .then(() => {
                  this.TeamSure();
                  this.$message({
                    type: "success",
                    message: "参与选择成交成功,请邀请你的团队."
                  });
                  this.TeamSure();
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "取消回答"
                  });
                });
              return;
            }
          }
        } else {
          this.$alert("已超过截止时间！回答将不在获得知识币！", "系统提示", {
            confirmButtonText: "确定",
            showClose: false,
            distinguishCancelAndClose: true,
            callback: action => {
              this.TeamSure();
            }
          });
          // this.$message({
          //       type: 'success',
          //       message: '已超过截止时间！回答将不在获得知识币！'
          //   });
          // this.TeamSure();
        }
      }
    },
    //点赞问题
    async fabuloused(e, id) {
      //获取登陆用户ID
      this.user = this.$store.state.userinfo.id;
      //获取点赞数的ID
      let datdse = null;
      let datas = null;
      let datad = null;
      if (this.user == null || this.user == "" || this.user < 0) {
        this.$message({
          type: "warning",
          message: "您还未登录，请您先登录！"
        });
      } else {
        //通过用户的ID和回答的ID查询是否点赞
        let totals = await QUERYED(
          "post",
          "",
          "   WDPraise(where: {user: {_eq: " +
            this.user +
            "}, praiseMan: {_eq: " +
            id +
            "}}) { id  }"
        );
        //  let fabul=totals.data.WDPraise.length;
        // 获取回答表的点赞数
        // datdse= await  QUERYED("post","",'  WDAnswerRef(where: {id: {_eq: '+id+'}}) { fabulous  }');
        //alert(datdse.data.WDQuestion[0].collectNum)
        //未点赞状态
        if (totals.data.WDPraise.length <= 0) {
          datas = await INSERT(
            "post",
            "",
            "    insert_WDPraise(objects: {praiseMan: " +
              id +
              ", user: " +
              this.user +
              ', collectDateTime: "' +
              new Date() +
              '"}) {affected_rows  }'
          );
          if (datas.data.insert_WDPraise.affected_rows > 0) {
            // datdse.data.WDAnswerRef[0].fabulous += 1
            this.basiced[e].fabuloused += 1; ////刷新点赞状态
            this.basiced[e].charge = "t";

            let datdeds = await UPDATE(
              "post",
              "",
              " update_WdAnswerRef(where: {id: {_eq: " +
                id +
                "}}, _set: {fabulous: " +
                this.basiced[e].fabuloused +
                "}) { affected_rows  }"
            );
            if (datdeds.data.update_WdAnswerRef.affected_rows > 0) {
              console.log("点赞成功" + this.datas);
              //更新显示点赞状态
              //  this.thumbs();//刷新点赞
              //  this.Deleterefresh();//刷新点赞数
              // this.basiced[e].fabuloused+=1;////刷新点赞状态
              // this.basiced[e].charge="true"[e];
            }
          }
        } else {
          datad = await DELETE(
            "post",
            "",
            "  delete_WdPraise(where: { user: {_eq: " +
              this.user +
              "}, praiseMan: {_eq: " +
              id +
              "}}) {affected_rows }"
          );
          if (datad.data.delete_WdPraise.affected_rows > 0) {
            if (this.basiced[e].fabuloused == 0) {
              this.basiced[e].fabuloused == 0;
            } else {
              this.basiced[e].fabuloused -= 1;
            }
            this.basiced[e].charge = "f";
            let datdeds = await UPDATE(
              "post",
              "",
              " update_WdAnswerRef(where: {id: {_eq: " +
                id +
                "}}, _set: {fabulous: " +
                this.basiced[e].fabuloused +
                "}) { affected_rows  }"
            );
            if (datdeds.data.update_WdAnswerRef.affected_rows > 0) {
              console.log("取消点赞" + this.datad);
              //更新显示点赞状态
              // this.thumbs();//刷新点赞
              // this.Deleterefresh();//刷新点赞数
              //  this.basiced[e].fabuloused-=1;////刷新点赞状态
              //  this.basiced[e].charge="false"[e];
            }
          }
        }
      }
    },
    //收藏问题
    async collectQuestion(WDCollect) {
      //添加
      let datas = null;
      //删除
      let datad = null;
      //收藏ID
      let datdse = null;
      //获取问题的ID
      this.problemid = this.$route.query.id;
      //获取登陆用户ID
      let user = null;
      this.user = this.$store.state.userinfo.id;
      if (this.user == null || this.user == "" || this.user < 0) {
        this.$message({
          type: "warning",
          message: "请您先登录！"
        });
      } else {
        // 获取问题表里面的收藏数
        datdse = await QUERYED(
          "post",
          "",
          "  WDQuestion(where: {id: {_eq: " +
            this.problemid +
            "}}) {  collectNum  }"
        );
        //alert(datdse.data.WDQuestion[0].collectNum)
        //未收藏状态
        if (WDCollect <= 0) {
          datas = await INSERT(
            "post",
            "",
            "   insert_WdCollect(objects: {question: " +
              this.problemid +
              ", user: " +
              this.user +
              ', collectDateTime: "' +
              new Date() +
              '"}) { affected_rows  }'
          );
          if (datas.data.insert_WdCollect.affected_rows > 0) {
            datdse.data.WDQuestion[0].collectNum += 1;
            let datdeds = await UPDATE(
              "post",
              "",
              "   update_WdQuestion(where: {id: {_eq: " +
                this.problemid +
                "}}, _set: {collectNum: " +
                datdse.data.WDQuestion[0].collectNum +
                "}) { affected_rows  }"
            );
            if (datdeds.data.update_WdQuestion.affected_rows > 0) {
              console.log(this.datas);
              //更新显示收藏状态
              this.Collection();
            }
          }
        } else {
          datad = await DELETE(
            "post",
            "",
            "  delete_WdCollect(where: {user: {_eq: " +
              this.user +
              "}, question: {_eq: " +
              this.problemid +
              "}}) {affected_rows  }"
          );
          if (datad.data.delete_WdCollect.affected_rows > 0) {
            if (datdse.data.WDQuestion[0].collectNum == 0) {
              datdse.data.WDQuestion[0].collectNum == 0;
            } else {
              datdse.data.WDQuestion[0].collectNum -= 1;
            }
            let datdeds = await UPDATE(
              "post",
              "",
              "   update_WdQuestion(where: {id: {_eq: " +
                this.problemid +
                "}}, _set: {collectNum: " +
                datdse.data.WDQuestion[0].collectNum +
                "}) { affected_rows  }"
            );
            if (datdeds.data.update_WdQuestion.affected_rows > 0) {
              console.log(this.datad);
              //更新显示收藏状态
              this.Collection();
            }
          }
        }
      }
    },
    //删除图片视频
    deleteThis(name) {
      this.file.forEach((item, index) => {
        if (item.name == name) {
          this.file.splice(index, 1);
          return;
        }
      });
    },
    //视频上传
    videoUpload(e) {
      let inputDOM = this.$refs.video;
      console.log(inputDOM.files[0]);
      if (
        inputDOM.files[0].type == "video/x-ms-wmv" ||
        inputDOM.files[0].type == "video/mp4" ||
        inputDOM.files[0].type == "video/x-ms-wmv"
      ) {
        this.fileLimit(inputDOM.files[0]);
      } else {
        //alert("格式不正确,请上传视频文件");
        this.$message({
          message: "格式不正确,请上传视频文件",
          type: "warning"
        });
      }
    },
    //图片上传
    imgUpload(e) {
      let inputDOM = this.$refs.img;
      console.log(inputDOM.files[0]);
      if (
        inputDOM.files[0].type == "image/png" ||
        inputDOM.files[0].type == "image/jpeg" ||
        inputDOM.files[0].type == "image/bmp"
      ) {
        this.fileLimit(inputDOM.files[0]);
      } else {
        //alert("格式不正确,请上传图片文件");
        this.$message({
          message: "格式不正确,请上传图片文件",
          type: "warning"
        });
      }
    },
    fileLimit(upFile) {
      let isRepeat = false; //判断重复
      let totleSize = 0; //总的字节数
      let fileLen = 0; //文件总个数
      if (this.file.length == 0) {
        totleSize = this.file.size;
      } else {
        this.file.forEach((item, index) => {
          if (item.name == upFile.name && item.type == upFile.type) {
            isRepeat = true;
          }
          totleSize = totleSize + item.size;
          fileLen++;
        });
      }
      if (isRepeat) {
        //alert("存在重复文件");
        this.$message({
          message: "存在重复文件",
          type: "warning"
        });
        return;
      }
      if (fileLen >= 7) {
        //alert("不能继续上传,文件个数已大于7");
        this.$message({
          message: "不能继续上传,文件个数已大于7",
          type: "warning"
        });
        return;
      }
      if (totleSize > 200 * 1024 * 1024) {
        //alert("文件总大超过200MB");
        this.$message({
          message: "文件总大超过200MB",
          type: "warning"
        });
        return;
      }
      this.file.push(upFile);
    },
    //提交答案
    submitAnswer() {
      this.saveFileAndAnswerInfo();
    },
    async saveInfo() {
      // if(this.reward.type==1){///如果是免费
      this.saveInfo1();
      // }
      // else if(this.reward.type==2){//如果是竞价
      //     this.saveInfo1();
      // }
    },
    async saveInfo1() {
      let charge = 0; //收费状态
      //获取问题编号
      this.problemid = this.$route.query.id;
      //     //获取登陆用户ID
      this.user = this.$store.state.userinfo.id;
      this.IA_inputw = this.answer.answer
        .replace(/<[^<>]+>/g, "")
        .replace(/&nbsp;/gi, "");
      ////获取登陆状态
      let token = localStorage.getItem("Authorization");
      if (
        token == "" ||
        token == null ||
        token == "undefined" ||
        this.user < 0 ||
        this.user == null ||
        this.user == "" ||
        this.user == "undefined"
      ) {
        this.$message({
          type: "warning",
          message: "您还未登录，请您先登录！"
        });
        //    this.$router.push({path: "/login"});
      } else {
        if (
          this.IA_inputw == "" &&
          this.videoDatas == "" &&
          this.upFiles == "" &&
          this.imgDatas == ""
        ) {
          this.$message({
            type: "warning",
            message: "回答的内容不能为空！"
          });
        } else {
          this.loading = true; ///显示正在加载中
          if (this.reward.type == 1) {
            charge = 0; //免费
          } else if (this.reward.type != 1) {
            ////判断该问题是否被采纳，如果被采纳那么收费状态为免费
            let infodata = await QUERYED(
              "post",
              "",
              "  WDAnswerRef_aggregate(where: {question: {_eq: " +
                this.problemid +
                "}, adopt: {_eq: 1}}){ aggregate {   count   }  }"
            );
            if (infodata.data.WDAnswerRef_aggregate.aggregate.count > 0) {
              ///如果已经被采纳
              charge = 0; //免费
            } else {
              // 判断有没有超过截至时间
              if (showTimeFormat(new Date()) <= this.reward.endTime) {
                //如果在时间内
                charge = 1; //收费
              } else {
                charge = 0; //免费
              }
            }
          }
          console.log(
            "是否被引用:" + this.Quoteornot + "回答ID：" + this.QuoteAnswer,
            "问题ID" + this.QuoteProblem
          );
          // 回答表添加数据
          let data = await INSERT(
            "post",
            "",
            " insert_WdAnswerRef(objects: {question: " +
              this.problemid +
              ", charge:" +
              charge +
              ",user: " +
              this.user +
              ', videos: "' +
              this.videoDatas +
              '", answerFiles: "' +
              this.upFiles +
              '", createtimes: "' +
              new Date() +
              '", fabulous: 0, answers: 0, imge: "' +
              this.imgDatas +
              '", answer: "' +
              this.IA_inputw +
              '",quetoType :"' +
              this.Quoteornot +
              '",quetoAnswer:' +
              this.QuoteAnswer +
              ",quetoPloblem :" +
              this.QuoteProblem +
              "}) {  affected_rows returning {      id    }  }"
          );
          if (data.data.insert_WdAnswerRef.affected_rows > 0) {
            ///竞价表添加数据
            let info = await INSERT(
              "post",
              "",
              'insert_WdCompetePrice(objects: {answerContent: "' +
                this.IA_inputw +
                '", answerDatetime: "' +
                new Date() +
                '", answerFiles: "' +
                this.upFiles +
                '", answerStatus: 0,question: ' +
                data.data.insert_WdAnswerRef.returning[0].id +
                ', isSuccess: 0, isInvied: 0, competeTime: "' +
                new Date() +
                '", competeType: 0, competeExpert: 0, competePay: ' +
                this.answer.completePay +
                "}) {    affected_rows    returning {      id    }  }"
            );
            if (info.data.insert_WdCompetePrice.affected_rows > 0) {
              //   问题表查询回答数
              let info = await QUERYED(
                "post",
                "",
                "  WDNewQuestion(where: {id: {_eq: " +
                  this.problemid +
                  "}}) { answerNum questioner questionTitle }"
              );
              //alert(info.data.WDNewQuestion[0].answerNum)
              // 回答数+=1
              let anser = 0;
              this.anser = info.data.WDNewQuestion[0].answerNum;
              this.anser += 1;
              //alert(this.problemid,this.this.anser)
              // 修改问题表中的回答数
              let dataed = await UPDATE(
                "post",
                "",
                "   update_WdQuestion(where: {id: {_eq: " +
                  this.problemid +
                  "}}, _set: {answerNum: " +
                  this.anser +
                  "}) { affected_rows  }"
              );
              if (dataed.data.update_WdQuestion.affected_rows > 0) {
                //  let   datdse= await  QUERYED("post","",'  WDQuestion(where: {id: {_eq: '+this.problemid+'}}) {  answerNum  }');
                //  datdse.data.WDQuestion[0].answerNum+=1
                //  let datdeds=await UPDATE("post","",'   update_WdQuestion(where: {id: {_eq: '+this.problemid+'}}, _set: {answerNum: '+datdse.data.WDQuestion[0].answerNum+'}) { affected_rows  }');
                //     if(datdeds.data.update_WdQuestion.affected_rows>0){

                // 判断截止时间是否超过
                // if(showTimeFormat(new Date())<=this.reward.endTime)
                // {///如果在时间内
                //  回答成功给发布问题者发一条消息
                let XXnotice = await INSERT(
                  "POST",
                  "",
                  "  insert_MessageNotification(objects: {userid: " +
                    info.data.WDNewQuestion[0].questioner +
                    ', type: 1, message: "' +
                    info.data.WDNewQuestion[0].questionTitle +
                    '",state:4, MessageAnswer: ' +
                    data.data.insert_WdAnswerRef.returning[0].id +
                    ', creatime: "' +
                    new Date() +
                    '", beuserid: ' +
                    this.user +
                    ", question: " +
                    this.problemid +
                    "}) { affected_rows  }"
                );
                if (
                  XXnotice.data.insert_MessageNotification.affected_rows == 1
                ) {
                  this.$message({
                    message: "回答成功！",
                    type: "success"
                  });
                  if (this.Quoteornot == 1) {
                    //引用
                    integralAddTwo(this.isjifen); //被引用加两分
                    integralAddTwo(this.user); //引用加两分
                  }
                  this.answer.answer = ""; //清空
                  this.isAnswer = false;
                  //判断是否展开
                  if (this.anniu == true) {
                    ////如果没展开
                    this.anserNum(); ////显示回答个数
                  } else {
                    ////如果展开了
                    if (this.comment == 0) {
                      ///如果回答个数为0////未被采纳
                      if (this.reward.type == 1) {
                        //如果是免费（悬赏）
                        this.answereds(); ////显示回答个数
                        this.quanbu(); ///展开回答详情
                        this.visabled = 1;
                      } else {
                        ///如果是收费（悬赏）
                        if (showTimeFormat(new Date()) <= this.reward.endTime) {
                          ////如果在时间内
                          ///未采纳不显示
                          ///如果在时间内
                          this.comment = 0; //回答个数
                          this.basiced = []; ////不展示
                          this.visabled = 0;
                        } else {
                          ////如超过了时间未被采纳
                          this.endTime(this.reward.endTime);
                          this.visabled = 1;
                        }
                        this.problrms(); //显示问题
                      }
                    } else {
                      ///如果回答个数不为0
                      if (this.reward.type == 1) {
                        //如果是免费（悬赏）
                        this.answereds(); ////显示回答个数
                        this.quanbu(); ///展开回答详情
                        this.visabled = 1;
                      } else {
                        ///如果是收费（悬赏）
                        //查询该问题是否被采纳
                        let dopt = await QUERYED(
                          "POST",
                          "",
                          "  WDUserQuestionAnswerRef(where: {wdquestionid: {_eq: " +
                            this.problemid +
                            "}, adopt: {_eq: 1}}) {  wdanswerref  }"
                        );
                        if (showTimeFormat(new Date()) <= this.reward.endTime) {
                          ////如果在时间内
                          if (dopt.data.WDUserQuestionAnswerRef.length > 0) {
                            ////如果被采纳
                            this.quanbu(); //调用显示被采纳详情
                            this.answereds(); //回答个数以及展开状态
                            this.visabled = 1;
                          } else {
                            ///未采纳不显示
                            ///如果在时间内
                            this.comment = 0; //回答个数
                            this.basiced = []; ////不展示
                            this.visabled = 0;
                          }
                        } else {
                          ////如超过了时间
                          if (dopt.data.WDUserQuestionAnswerRef.length > 0) {
                            ////如何超过了时间但是被采纳了
                            this.quanbu(); //调用显示被采纳详情
                            this.answereds(); //回答个数以及展开状态
                            this.visabled = 1;
                          } else {
                            ///如果超过了时间但是未被采纳
                            this.endTime(this.reward.endTime);
                            this.visabled = 1;
                          }
                        }
                        this.problrms(); //显示问题
                      }
                    }
                  }
                  AcceptNum(this.user); //传递用户ID(修改用户)
                  AcceptNumTeam(this.user); //传递用户ID（修改团队）
                  this.loading = false; ///隐藏显示正在加载中
                }
              } else {
                this.$message({
                  message: "回答失败！",
                  type: "error"
                });
              }
            } else {
              this.$message({
                message: "回答失败！",
                type: "error"
              });
            }
          }
        }
      }
    },
    //保存文件和答案信息
    saveFileAndAnswerInfo() {
      if (this.upFiles.length <= 0) {
        this.saveInfo();
        return;
      } else {
        var files = this.upFiles;
        let formdata = new FormData();
        formdata.append("file", files[0]);

        axios({
          url: "http://192.168.1.120:9527/resources/trading/resourcesUpload",
          data: formdata,
          method: "post"
        })
          .then(resp => {
            console.log(resp);
            //alert(JSON.stringify(JSON.stringify(resp.data)))
            this.upFiles = resp.data.data.url;
            //alert(this.upFiles)
            this.saveInfo();
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
    //我要回答
    async answerTheQuestions() {
      this.$alert("已经有人先你一步抢答了哦！", "系统提示", {
        dangerouslyUseHTMLString: true
      });
    },
    //我要回答
    async answerTheQuestion() {
      //如果是免费
      if (this.reward.type == 1) {
        this.isAnswer = true;
        return;
      } else {
        if (showTimeFormat(new Date()) <= this.reward.endTime) {
          if (this.isAnswer) {
            return;
          }
          let rewardPay = this.reward.pay;
          if (this.reward.type == 2) {
            //  判断该问题有没有被采纳
            //  查询是否被采纳
            let dopt = await QUERYED(
              "POST",
              "",
              "  WDUserQuestionAnswerRef(where: {wdquestionid: {_eq: " +
                this.problemid +
                "}, adopt: {_eq: 1}}) {  wdanswerref  }"
            );
            if (dopt.data.WDUserQuestionAnswerRef.length > 0) {
              ///如果被采纳
              this.$alert("已经有人被采纳了哦！", "系统提示", {
                confirmButtonText: "确定",
                showClose: false,
                distinguishCancelAndClose: true,
                callback: action => {
                  this.isAnswer = true;
                }
              });
            } else {
              this.$prompt(
                "参与竞价,竞价金额不能超过" + this.reward.pay,
                "竞价",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  closeOnClickModal: false,
                  inputPattern: /^\d+$/,
                  inputErrorMessage: "竞价金额为不大于" + rewardPay + "的整数",
                  inputValidator: function(value) {
                    if (value <= rewardPay) {
                      return true;
                    }
                    return false;
                  }
                }
              )
                .then(({ value }) => {
                  this.isAnswer = true;
                  this.answer.completePay = value;
                  this.$message({
                    type: "success",
                    message:
                      "您的竞价金额是: " + value + "知识币,请输入您的答案."
                  });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "取消输入"
                  });
                });
              return;
            }
          }
          if (this.reward.type == 3) {
            //  判断该问题有没有被采纳
            //  查询是否被采纳
            let dopt = await QUERYED(
              "POST",
              "",
              "  WDUserQuestionAnswerRef(where: {wdquestionid: {_eq: " +
                this.problemid +
                "}, adopt: {_eq: 1}}) {  wdanswerref  }"
            );
            if (dopt.data.WDUserQuestionAnswerRef.length > 0) {
              ///如果被采纳
              this.$alert(
                "问题已经被采纳，继续回答将不会获得知识币！",
                "系统提示",
                {
                  confirmButtonText: "确定",
                  showClose: false,
                  distinguishCancelAndClose: true,
                  callback: action => {
                    this.isAnswer = true;
                  }
                }
              );
            } else {
              this.$confirm(
                "抢答被采纳后将获得" + this.reward.pay + "知识币",
                "抢答",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }
              )
                .then(() => {
                  this.isAnswer = true;
                  this.$message({
                    type: "success",
                    message: "参与抢答成功,请输入您的答案."
                  });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "取消抢答"
                  });
                });
              return;
            }
          }
          if (this.reward.type == 4) {
            //  判断该问题有没有被采纳
            //  查询是否被采纳
            let dopt = await QUERYED(
              "POST",
              "",
              "  WDUserQuestionAnswerRef(where: {wdquestionid: {_eq: " +
                this.problemid +
                "}, adopt: {_eq: 1}}) {  wdanswerref  }"
            );
            if (dopt.data.WDUserQuestionAnswerRef.length > 0) {
              ///如果被采纳
              this.$alert("已经有人被采纳了哦！", "系统提示", {
                confirmButtonText: "确定",
                showClose: false,
                distinguishCancelAndClose: true,
                callback: action => {
                  this.isAnswer = true;
                }
              });
            } else {
              this.$confirm(
                "回答被采纳后将获得" + this.reward.pay + "知识币",
                "信用度",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }
              )
                .then(() => {
                  this.isAnswer = true;
                  this.$message({
                    type: "success",
                    message: "参与信用度回答成功,请输入您的答案."
                  });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "取消回答"
                  });
                });
              return;
            }
          }
          if (this.reward.type == 5) {
            //  判断该问题有没有被采纳
            //  查询是否被采纳
            let dopt = await QUERYED(
              "POST",
              "",
              "  WDUserQuestionAnswerRef(where: {wdquestionid: {_eq: " +
                this.problemid +
                "}, adopt: {_eq: 1}}) {  wdanswerref  }"
            );
            if (dopt.data.WDUserQuestionAnswerRef.length > 0) {
              ///如果被采纳
              this.$alert("已经有人被采纳了哦！", "系统提示", {
                confirmButtonText: "确定",
                showClose: false,
                distinguishCancelAndClose: true,
                callback: action => {
                  this.isAnswer = true;
                }
              });
            } else {
              this.$confirm(
                "回答被采纳后将获得" + this.reward.pay + "知识币",
                "选择成交",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }
              )
                .then(() => {
                  this.isAnswer = true;
                  this.$message({
                    type: "success",
                    message: "参与选择成交成功,请输入您的答案."
                  });
                  this.isAnswer = true;
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "取消回答"
                  });
                });
              return;
            }
          }
        } else {
          this.$alert("已超过截止时间！回答将不在获得知识币！", "系统提示", {
            confirmButtonText: "确定",
            showClose: false,
            distinguishCancelAndClose: true,
            callback: action => {
              this.isAnswer = true;
            }
          });
        }
      }
    },
    //添加上传文件
    fileUpload(e) {
      if (this.upFiles.length < 1) {
        let inputDOM = this.$refs.file;
        console.log(inputDOM.files[0]);
        this.upFiles.push(inputDOM.files[0]);
      } else {
        //alert("只能上传一个文件！");
        this.$message({
          message: "只能上传一个文件！",
          type: "warning"
        });
      }
    },
    //移除文件
    removeFile(index) {
      this.upFiles.splice(index, 1);
    },
    async www(name) {
      await axios
        .post(
          "http://39.100.48.36:3016/getData",
          {
            content: name
          },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(response => {
          // console.log("敏感词过滤："+JSON.stringify(response.data.message.filterContent))
          this.centers = response.data.message.filterContent;
          console.log("赋值成功：" + this.centers);
        })
        .catch(function(error) {
          console.log("错误" + error);
        });
    },
    //数据传递用
    async change() {
      await this.www(this.answer.answer); ///请求接口
      this.answer.answer = this.centers; //赋值
      // console.log("赋值成功："+this.answer.answer)
      // console.log(val)
    },

    ///显示问题
    async problrms() {
      //alert(1)
      this.problemid = this.$route.query.id;
      let data = await QUERYED(
        "post",
        "",
        "  WDNewQuestion(where: {id: {_eq: " +
          this.problemid +
          "}}) {questionerPercentage answererPercentage createTime keyword id questionTitle name  competePriceSetting questionContent   img  video isViewFree viewPrice  answerAdress  answerStartTime problemClassification answerEndTime  answerWay  competeDeadlineTime  competeMode }"
      );
      this.questions.splice(0, this.questions.length);
      for (let i = 0; i < data.data.WDNewQuestion.length; i++) {
        let { img, video } = data.data.WDNewQuestion[i];
        img = img ? img : "";
        this.questions.push({
          id: data.data.WDNewQuestion[i].id,
          name: data.data.WDNewQuestion[i].name,
          questionTitle: data.data.WDNewQuestion[i].questionTitle,
          describe: data.data.WDNewQuestion[i].questionContent,

          // img:img.indexOf('http')>-1?img:this.api.LoginURL.concat(img),
          // video:video.indexOf('http')>-1?video:this.api.LoginURL.concat(video),
          img: img&&img!='null'?(img.indexOf("http") > -1 ? img : this.api.LoginURL.concat(img)):'',
          video: this.api.LoginURL.concat(video),

          createTime: showTimeFormat(data.data.WDNewQuestion[i].createTime),
          keywords: data.data.WDNewQuestion[i].keyword,
          competePriceSetting: data.data.WDNewQuestion[i].competePriceSetting,
          problemClassification:
            data.data.WDNewQuestion[i].problemClassification
        });
        // 悬赏类型
        this.reward.type = data.data.WDNewQuestion[0].competeMode;
        this.reward.pay = data.data.WDNewQuestion[0].competePriceSetting;
        this.reward.endTime = showTimeFormat(
          data.data.WDNewQuestion[0].competeDeadlineTime
        );
        //    查看分成
        this.shared.type = data.data.WDNewQuestion[0].isViewFree;
        this.shared.pay = data.data.WDNewQuestion[0].viewPrice;
        this.shared.release = data.data.WDNewQuestion[0].questionerPercentage;
        this.shared.answer = data.data.WDNewQuestion[0].answererPercentage;
        //   求解方式
        this.solve.type = data.data.WDNewQuestion[0].answerWay;
        this.solve.startTime = showTimeFormat(
          data.data.WDNewQuestion[0].answerStartTime
        );
        this.solve.endTime = showTimeFormat(
          data.data.WDNewQuestion[0].answerEndTime
        );
        this.solve.adress = data.data.WDNewQuestion[0].answerAdress;
        ///判断抢答是否被回答
        if (this.reward.type == 3) {
          //判断抢答类型是否有人回答了
          // 如果有人回答
          let data = await QUERYED(
            "post",
            "",
            "  WDAnswerRef_aggregate(where: {question: {_eq: " +
              this.problemid +
              "}, adopt: {_eq: 0}}){ aggregate {   count   }  }"
          );
          if (data.data.WDAnswerRef_aggregate.aggregate.count > 0) {
            //如果已经有人回答
            //判断是否有人被采纳
            let dopt = await QUERYED(
              "POST",
              "",
              "  WDUserQuestionAnswerRef(where: {wdquestionid: {_eq: " +
                this.problemid +
                "}, adopt: {_eq: 1}}) {  wdanswerref  }"
            );
            if (dopt.data.WDUserQuestionAnswerRef.length > 0) {
              //如果被采纳
              this.Buttoms = true; //蓝色
            } else {
              ///如果未被采纳
              // 如果在时间内
              if (showTimeFormat(new Date()) <= this.reward.endTime) {
                ///在截至时间内
                this.Buttoms = false; //灰色
              } else {
                ///如果超过截止时间
                this.Buttoms = true; //蓝色
              }
            }
          } else {
            //如果暂无回答
            this.Buttoms = true; //蓝色
          }
        } else {
          this.Buttoms = true;
        }
        //  this.answeredss();
      }

      // console.log(this.questions);
    },
    //显示全部问题回答个数
    async anserNum() {
      let problemid = this.$route.query.id;
      let totals = await QUERYED(
        "post",
        "",
        "    WDUserQuestionAnswerRef_aggregate(where: {wdquestionid: {_eq: " +
          problemid +
          "}, _not: {adopt: {_eq: 2}}, _and: {_not: {charge: {_eq: 1}}}}) { aggregate {   count   } }"
      );
      this.comment =
        totals.data.WDUserQuestionAnswerRef_aggregate.aggregate.count;
      if (this.comment != 0) {
        this.anniu = true; //显示展开
      } else {
        this.anniu = false; //隐藏展开
      }
    },
    //初始化显示回答个数
    async answeredss() {
      this.problemid = this.$route.query.id;
      let totals = await QUERYED(
        "post",
        "",
        "    WDUserQuestionAnswerRef_aggregate(where: {wdquestionid: {_eq: " +
          this.problemid +
          "}, _not: {adopt: {_eq: 2}}, _and: {_not: {charge: {_eq: 1}}}}) { aggregate {   count   } }"
      );
      if (this.reward.type == 1) {
        //如果是免费（悬赏）
        this.comment =
          totals.data.WDUserQuestionAnswerRef_aggregate.aggregate.count;
        if (this.comment != 0) {
          this.anniu = true; //显示展开
        } else {
          this.anniu = false; //显示展开
        }
      } else {
        //  查询是否被采纳
        let dopt = await QUERYED(
          "POST",
          "",
          "  WDUserQuestionAnswerRef(where: {wdquestionid: {_eq: " +
            this.problemid +
            "}, adopt: {_eq: 1}}) {  wdanswerref  }"
        );
        if (dopt.data.WDUserQuestionAnswerRef.length > 0) {
          ///如果被采纳
          this.comment =
            totals.data.WDUserQuestionAnswerRef_aggregate.aggregate.count;
          if (this.comment != 0) {
            this.anniu = true; //显示展开
          } else {
            this.anniu = false; //隐藏展开
          }
        } else {
          ///未被采纳
          this.comment = 0;
          this.anniu = false; //隐藏展开
        }
      }
    },
    //调用显示回答个数
    async answereds() {
      this.problemid = this.$route.query.id;
      let totals = await QUERYED(
        "post",
        "",
        "    WDUserQuestionAnswerRef_aggregate(where: {wdquestionid: {_eq: " +
          this.problemid +
          "}, _not: {adopt: {_eq: 2}}, _and: {_not: {charge: {_eq: 1}}}}) { aggregate {   count   } }"
      );
      if (this.reward.type == 1) {
        //如果是免费
        this.comment =
          totals.data.WDUserQuestionAnswerRef_aggregate.aggregate.count;
      } else {
        //  查询是否被采纳
        let dopt = await QUERYED(
          "POST",
          "",
          "  WDUserQuestionAnswerRef(where: {wdquestionid: {_eq: " +
            this.problemid +
            "}, adopt: {_eq: 1}}) {  wdanswerref  }"
        );
        if (dopt.data.WDUserQuestionAnswerRef.length > 0) {
          ///如果被采纳
          //
          this.comment =
            totals.data.WDUserQuestionAnswerRef_aggregate.aggregate.count;
        } else {
          this.comment = 0;
        }
      }
    },
    //   展开显示全部回答包含免费问题
    async quanbu() {
      
      this.basiced = [];
      this.basiced.replyList = [];
      this.basiced.replyList.children = [];
      let a = ""; //回答内容
      let p = []; //多人回答
      this.sons = []; //多人回答
      this.problemid = this.$route.query.id;
      let data = await QUERYED(
        "post",
        "",
        "  WDUserQuestionAnswerRef(where:{wdquestionid: {_eq: " +
          this.problemid +
          "}, _not: {adopt: {_eq: 2}}, _and: {_not: {charge: {_eq: 1}}}}) {adopt mediaPathArray wdanswerref user  answers wdquestionid  img videos name  intro person answer  imge answerFiles id external_problemid external_teamid createtimes  fabuloused }"
      );
      for (let i = 0; i < data.data.WDUserQuestionAnswerRef.length; i++) {
        a = data.data.WDUserQuestionAnswerRef[i]; ///显示回答
        console.log("回答1111111", a.mediaPathArray);
        if (data.data.WDUserQuestionAnswerRef[i].person == 1) {
          ///通过外部团队ID和问题ID查找流水表
          let liushui = await QUERYED(
            "post",
            "",
            "  WDpeople_answer(where:{ques_id: {_eq: " +
              data.data.WDUserQuestionAnswerRef[i].external_problemid +
              "},team_id: {_eq: " +
              data.data.WDUserQuestionAnswerRef[i].external_teamid +
              "}}) {name img intro id usersid submit_time attachment_url answer_content}"
          );
          // console.log("this.sons[i].grandson[f]",this.sons)
          for (let f = 0; f < liushui.data.WDpeople_answer.length; f++) {
            let imageUrl = liushui.data.WDpeople_answer[f].img
              ? liushui.data.WDpeople_answer[f].img
              : "";
              if(imageUrl&&imageUrl!='null'){
                liushui.data.WDpeople_answer[f].img =
                  imageUrl.indexOf("http") > -1
                    ? imageUrl
                    : this.api.LoginURL.concat(imageUrl);
              }else{
                liushui.data.WDpeople_answer[f].img =''
              }
            // liushui.data.WDpeople_answer[f].img = this.api.LoginURL.concat(
            //   liushui.data.WDpeople_answer[f].img
            // );
            // this.sons.push({
            //   usersid: liushui.data.WDpeople_answer[f].usersid,
            //   id: liushui.data.WDpeople_answer[f].id,
            //   name: liushui.data.WDpeople_answer[f].name,
            //   img: this.api.LoginURL.concat(
            //     liushui.data.WDpeople_answer[f].img
            //   ),
            //   intro: liushui.data.WDpeople_answer[f].intro,
            //   // "submit_time":formatDate(
            //   //   Date.parse(liushui.data.WDpeople_answer[f].submit_time)),
            //   answer_content: liushui.data.WDpeople_answer[f].answer_content,
            //   // attachment_url: this.api.LoginURL.concat(
            //   //   liushui.data.WDpeople_answer[f].attachment_url
            //   // ),
            //   attachment_url: liushui.data.WDpeople_answer[f].attachment_url,
            // });
          }
          a.mediaPathArray = liushui.data.WDpeople_answer;
          // console.log("多人问答：", this.sons);
        }
        //  判断是否登陆
        if (
          this.$store.state.userinfo.id != undefined &&
          this.$store.state.userinfo.id != "" &&
          this.$store.state.userinfo.id != null
        ) {
          //如果登陆了
          //根据回答ID查找是否被点赞
          let ddtata = await QUERYED(
            "POST",
            "",
            "WDPraise(where: {praiseMan: {_eq: " +
              data.data.WDUserQuestionAnswerRef[i].wdanswerref +
              "}, user: {_eq: " +
              this.$store.state.userinfo.id +
              "}}) { id  }"
          );
          if (ddtata.data.WDPraise.length > 0) {
            //被点赞了
            a.charge = "t";
          } else {
            //没有被点赞
            a.charge = "f";
          }
        } else {
          //根据回答ID查找是否被点赞
          a.charge = "f";
        }
        a.img = this.api.LoginURL.concat(a.img);
        a.imge = this.api.LoginURL.concat(a.imge); //转换图片
        a.videos = this.api.LoginURL.concat(a.videos); //转换视频
        // a.answerFiles = this.api.LoginURL.concat(a.answerFiles); //转换附件
        if(a.answerFiles&&a.answerFiles!='null'){
          a.answerFiles = a.answerFiles.indexOf("http") > -1?a.answerFiles:this.api.LoginURL.concat(a.answerFiles)
        }else{
          a.answerFiles = ''
        }
        a.createtimes = formatDate(Date.parse(a.createtimes));
        ////根据回答ID查询一级评论
        let info = await QUERYED(
          "post",
          "",
          "   WDOneComment(where: {whole: {_eq: " +
            data.data.WDUserQuestionAnswerRef[i].wdanswerref +
            "}, answer: {_eq: " +
            data.data.WDUserQuestionAnswerRef[i].wdanswerref +
            "}}) {      ancestorCommentId authorId answer   authorId   answers    authorImg   authorName   commentator   content    fabulous   id    whole    user   type   time   question parentCommentId  imge  isShield }"
        );
        for (let i = 0; i < info.data.WDOneComment.length; i++) {
          info.data.WDOneComment[i].time = formatDate(
            Date.parse(info.data.WDOneComment[i].time)
          );
          info.data.WDOneComment[i].authorImg = this.api.LoginURL.concat(
            info.data.WDOneComment[i].authorImg
          );
        }
        a.replyList = info.data.WDOneComment; ///显示评论一级

        for (let j = 0; j < info.data.WDOneComment.length; j++) {
          console.log(
            "whole:" + data.data.WDUserQuestionAnswerRef[i].wdanswerref,
            "ancestorCommentId" + a.replyList[j].id,
            "answer" + data.data.WDUserQuestionAnswerRef[i].wdanswerref
          );
          // 显示二级评论
          let info_c = await QUERYED(
            "post",
            "",
            "   WDTwoCommentS(where: {whole: {_eq: " +
              data.data.WDUserQuestionAnswerRef[i].wdanswerref +
              "}, ancestorCommentId: {_eq: " +
              a.replyList[j].id +
              "}, answer: {_eq: " +
              data.data.WDUserQuestionAnswerRef[i].wdanswerref +
              "}})  {   ancestorCommentId  answer UserauthorImg  UserauthorId UserauthorName  authorId   answers    authorImg   authorName   commentator   content    fabulous   id    whole    user   type   time   question parentCommentId  imge  isShield }"
          );
          //  this.children.splice(0,this.children.length);

          for (let w = 0; w < a.replyList.length; w++) {
            //  a.replyList[w].children.id=info_c.data.WDTwoCommentS.id
            //  a.replyList[w].children.UserauthorName=info_c.data.WDTwoCommentS.UserauthorName
            // if(info_c.data.WDTwoCommentS.length!=0)
            // {
            //    a.replyList.children = info_c.data.WDTwoCommentS
            // }
            //  a.replyList.push(info_c.data.WDTwoCommentS)

            if (info_c.data.WDTwoCommentS.length !== 0) {
              if (
                a.replyList[w].id ==
                info_c.data.WDTwoCommentS[0].ancestorCommentId
              ) {
                a.replyList[w].children = info_c.data.WDTwoCommentS;
              }
            }
            console.log(a.replyList);
            //   info_c.data.WDTwoCommentS[i].UserauthorImg = this.api.LoginURL.concat(
            //     info_c.data.WDTwoCommentS[i].UserauthorImg
            //   );
            //  info_c.data.WDTwoCommentS[i].authorImg = this.api.LoginURL.concat(
            //     info_c.data.WDTwoCommentS[i].authorImg
            //   );
          }
          //  a.replyList.children.time=formatDate(Date.parse(a.replyList.children.time));//转换时间
          //  a.replyList.children = info_c.data.WDTwoCommentS;///显示评论2级
        }
        
        this.basiced.push(a);
      }
    },
    /////显示点赞的状态
    // async thumbs(){
    //        this.problemid=this.$route.query.id;
    //       let data=  await   QUERYED("post","",'  WDUserQuestionAnswerRef(where:{wdquestionid: {_eq: '+this.problemid+'}}) {wdanswerref user  answers wdquestionid  img videos name  intro  answer  imge answerFiles id  createtimes  fabuloused }');
    //      // let datas=await   QUERYED("post","",'   WDUserQuestionAnswerReffabulous(  where: {wdquestionid: {_eq: '+this.problemid+'}},limit: 2) {wdanswerref user  answers wdquestionid  img videos name  intro  answer  imge   createtimes  fabuloused }');
    //       for (let i = 0; i < data.data.WDUserQuestionAnswerRef.length; i++) {
    //     //查询该回答是否被点赞
    //     let ddtata=await QUERYED("POST","",'  WDPraise(where: {praiseMan: {_eq: '+data.data.WDUserQuestionAnswerRef[i].wdanswerref+'}}) { id  }')
    //        ///被点赞
    //      if(ddtata.data.WDPraise.length>0){
    //          this.fabul=0;
    //      }
    //      else{
    //          this.fabul=1;
    //      }
    //   }
    // },
    // 引用答案

    //  触发引用按钮，打开相似答案
    async Quote() {
      this.$refs.footer.$emit("bridge", this.answer.completePay);
      console.log("问题ID：" + this.problemid);
      this.Quotes = true;
    },
    // 初始化显示回答详情
    async answered() {
      this.loading = true; ///显示正在加载中
      this.basiced = []; ////不展示
      //   this.problemid=this.$route.query.id;
      //   ///查询悬赏的状态
      let data = await QUERYED(
        "post",
        "",
        "  WDNewQuestion(where: {id: {_eq: " +
          this.problemid +
          "}}) {  isViewFree competeMode}"
      );
      /////先判断是否是悬赏问题
      if (data.data.WDNewQuestion[0].competeMode == 1) {
        ///如果悬赏问题不是免费
        this.detailsed(); //显示详情
        this.anserNum(); ///回答个数
      } else {
        ////如果是收费问题
        //查询该问题是否被采纳
        let dopt = await QUERYED(
          "POST",
          "",
          "  WDUserQuestionAnswerRef(where: {wdquestionid: {_eq: " +
            this.problemid +
            "}, adopt: {_eq: 1}, _and: {_not: {charge: {_eq: 1}}}})  {  wdanswerref  }"
        );
        if (dopt.data.WDUserQuestionAnswerRef.length > 0) {
          ////如果被采纳
          this.Beadopt(); //调用显示被采纳详情
          this.answeredss(); //回答个数以及展开状态
        } else {
          ///未采纳不显示
          let data = await QUERYED(
            "POST",
            "",
            "  WDQuestion(where: {id: {_eq: " +
              this.problemid +
              "}}) { competeDeadlineTime  }"
          );
          //alert(showTimeFormat(data.data.WDQuestion[0].competeDeadlineTime))
          if (
            showTimeFormat(new Date()) <=
            showTimeFormat(data.data.WDQuestion[0].competeDeadlineTime)
          ) {
            //判断是否到了截止时间
            ///如果在时间内
            this.comment = 0;
            this.basiced = []; ////不展示
          } else {
            ////只展示在时间外发表的回答以显示回答个数
            this.endTime(
              showTimeFormat(data.data.WDQuestion[0].competeDeadlineTime)
            );
            this.visabled = 1;
          }
          this.loading = false; ///隐藏正在加载中
        }
      }
      this.loading = false; ///隐藏正在加载中
    },
    ///初始化展示未被采纳又超过截止时间的答案
    ///截止时间展示的答案
    async endTime(Time) {
      this.basiced = [];
      this.limit = this.limit;
      let a = null;
      let data = await QUERYED(
        "post",
        "",
        " WDUserQuestionAnswerRef(where:{wdquestionid: {_eq: " +
          this.problemid +
          "},_not: {adopt: {_eq: 2}}} ) {wdanswerref mediaPathArray charge adopt user person answers wdquestionid id img videos name  intro  answer  imge answerFiles   createtimes  fabuloused }"
      );
      for (let i = 0; i < data.data.WDUserQuestionAnswerRef.length; i++) {
        if (
          showTimeFormat(data.data.WDUserQuestionAnswerRef[i].createtimes) >
          Time
        ) {
          //如果超过时间
          a = data.data.WDUserQuestionAnswerRef[i];
          if (data.data.WDUserQuestionAnswerRef[i].person == 1) {
            ///通过外部团队ID和问题ID查找流水表
            let liushui = await QUERYED(
              "post",
              "",
              "  WDpeople_answer(where:{ques_id: {_eq: " +
                data.data.WDUserQuestionAnswerRef[i].external_problemid +
                "},team_id: {_eq: " +
                data.data.WDUserQuestionAnswerRef[i].external_teamid +
                "}}) {name img intro usersid id submit_time attachment_url answer_content}"
            );
            // a.sons=liushui.data.WDpeople_answer[f]
            for (let f = 0; f < liushui.data.WDpeople_answer.length; f++) {
              let imageUrl1 = liushui.data.WDpeople_answer[f].img
                ? liushui.data.WDpeople_answer[f].img
                : "";
                if(imageUrl1&&imageUrl1!='null'){
                  liushui.data.WDpeople_answer[f].img =
                  imageUrl1.indexOf("http") > -1
                  ? imageUrl1
                  : this.api.LoginURL.concat(imageUrl1);
                }else{
                  liushui.data.WDpeople_answer[f].img =''
                }
              // this.sons.push({
              //   usersid: liushui.data.WDpeople_answer[f].usersid,
              //   id: liushui.data.WDpeople_answer[f].id,
              //   name: liushui.data.WDpeople_answer[f].name,
              //   img: this.api.LoginURL.concat(
              //     liushui.data.WDpeople_answer[f].img
              //   ),
              //   intro: liushui.data.WDpeople_answer[f].intro,
              //   // "submit_time":formatDate(
              //   //   Date.parse(liushui.data.WDpeople_answer[f].submit_time)),
              //   answer_content: liushui.data.WDpeople_answer[f].answer_content,
              //   // attachment_url: this.api.LoginURL.concat(
              //   //   liushui.data.WDpeople_answer[f].attachment_url
              //   // ),
              //   attachment_url: liushui.data.WDpeople_answer[f].attachment_url,
              // });
            }
            a.mediaPathArray = liushui.data.WDpeople_answer;
            // console.log("多人问答：", this.sons);
          }
          //  判断是否登陆
          if (
            this.$store.state.userinfo.id != undefined &&
            this.$store.state.userinfo.id != "" &&
            this.$store.state.userinfo.id != null
          ) {
            //如果登陆了
            //根据回答ID查找是否被点赞
            let ddtata = await QUERYED(
              "POST",
              "",
              "WDPraise(where: {praiseMan: {_eq: " +
                data.data.WDUserQuestionAnswerRef[i].wdanswerref +
                "}, user: {_eq: " +
                this.$store.state.userinfo.id +
                "}}) { id  }"
            );
            if (ddtata.data.WDPraise.length > 0) {
              //被点赞了
              a.charge = "t";
            } else {
              //没有被点赞
              a.charge = "f";
            }
          } else {
            //根据回答ID查找是否被点赞
            a.charge = "f";
          }
          if (a.img) a.img = a.img ? a.img : "";
          a.img =
            a.img.indexOf("http") > -1
              ? a.img
              : this.api.LoginURL.concat(a.img);

          if (a.imge) a.imge = a.imge ? a.imge : "";
          a.imge =
            a.imge.indexOf("http") > -1
              ? a.imge
              : this.api.LoginURL.concat(a.imge); //转换图片
          a.createtimes = formatDate(Date.parse(a.createtimes));
          if (a.videos) a.videos = a.videos ? a.videos : "";
          a.videos =
            a.videos.indexOf("http") > -1
              ? a.videos
              : this.api.LoginURL.concat(a.videos); //转换视频
          if (a.answerFiles) a.answerFiles = a.answerFiles ? a.answerFiles : "";
          a.answerFiles = (a.answerFiles =='null'||!a.answerFiles?'':a.answerFiles.indexOf("http") > -1?a.answerFiles:this.api.LoginURL.concat(a.answerFiles))
          // a.answerFiles =
          //   a.answerFiles.indexOf("http") > -1
          //     ? a.answerFiles
          //     : this.api.LoginURL.concat(a.answerFiles); //转换附件
          let info = null;
          ////根据回答ID查询一级评论
          info = await QUERYED(
            "post",
            "",
            "   WDOneComment(where: {whole: {_eq: " +
              data.data.WDUserQuestionAnswerRef[i].wdanswerref +
              "}, answer: {_eq: " +
              data.data.WDUserQuestionAnswerRef[i].wdanswerref +
              "}}) {      ancestorCommentId authorId answer   authorId   answers    authorImg   authorName   commentator   content    fabulous   id    whole    user   type   time   question parentCommentId  imge  isShield }"
          );
          for (let i = 0; i < info.data.WDOneComment.length; i++) {
            info.data.WDOneComment[i].time = formatDate(
              Date.parse(info.data.WDOneComment[i].time)
            );
            info.data.WDOneComment[i].authorImg = this.api.LoginURL.concat(
              info.data.WDOneComment[i].authorImg
            );
          }
          a.replyList = info.data.WDOneComment; ///显示评论一级
          for (let j = 0; j < info.data.WDOneComment.length; j++) {
            // 显示二级评论
            let info_c = await QUERYED(
              "post",
              "",
              "   WDTwoCommentS(where: {whole: {_eq: " +
                data.data.WDUserQuestionAnswerRef[i].wdanswerref +
                "}, ancestorCommentId: {_eq: " +
                a.replyList[j].id +
                "}, answer: {_eq: " +
                data.data.WDUserQuestionAnswerRef[i].wdanswerref +
                "}})  {  ancestorCommentId  answer UserauthorImg  UserauthorId UserauthorName  authorId   answers    authorImg   authorName   commentator   content    fabulous   id    whole    user   type   time   question parentCommentId  imge  isShield }"
            );
            for (let w = 0; w < a.replyList.length; w++) {
              if (info_c.data.WDTwoCommentS.length !== 0) {
                if (
                  a.replyList[w].id ==
                  info_c.data.WDTwoCommentS[0].ancestorCommentId
                ) {
                  a.replyList[w].children = info_c.data.WDTwoCommentS;
                }
              }
              console.log(a.replyList);
            }
          }
          this.basiced.push(a);
        }
        this.comment = this.basiced.length; //回答个数
      }
    },
    //  初始化显示被采纳的内容
    async Beadopt() {
      this.basiced = [];
      let a = null;
      this.problemid = this.$route.query.id;
      // 判断是否是免费
      let data = await QUERYED(
        "post",
        "",
        "  WDUserQuestionAnswerRef(where: {adopt: {_eq: 1}, wdquestionid: {_eq: " +
          this.problemid +
          "}},limit:1) {wdanswerref adopt user  answers wdquestionid id img person videos name  intro  answer  imge answerFiles   createtimes  fabuloused }"
      );
      for (let i = 0; i < data.data.WDUserQuestionAnswerRef.length; i++) {
        a = data.data.WDUserQuestionAnswerRef[i]; ////显示回答
        a.img = this.api.LoginURL.concat(a.img);
        a.imge = this.api.LoginURL.concat(a.imge); //转换图片
        a.createtimes = formatDate(Date.parse(a.createtimes));
        this.basiced.push(a);
      }
    },
    // 初始化第一次显示详情回答
    async detailsed() {
      this.basiced = [];
      let a = null;
      this.problemid = this.$route.query.id;
      let data = await QUERYED(
        "post",
        "",
        "  WDUserQuestionAnswerRef(where:{wdquestionid: {_eq: " +
          this.problemid +
          "},_not: {adopt: {_eq: 2}}},limit:1) {adopt wdanswerref user person  answers wdquestionid id img videos name  intro  answer  imge answerFiles   createtimes  fabuloused }"
      );
      // let datas=await   QUERYED("post","",'   WDUserQuestionAnswerReffabulous(  where: {wdquestionid: {_eq: '+this.problemid+'}},limit: 2) {wdanswerref user  answers wdquestionid  img videos name  intro  answer  imge   createtimes  fabuloused }');
      for (let i = 0; i < data.data.WDUserQuestionAnswerRef.length; i++) {
        //alert(data.data.WDUserQuestionAnswerRef[i].wdanswerref)
        a = data.data.WDUserQuestionAnswerRef[i]; ////显示回答
        a.img=a.img?a.img:''
        a.img =
          a.img.indexOf("http") > -1 ? a.img : this.api.LoginURL.concat(a.img);
          a.imge =a.imge ?a.imge :''
        a.imge =
          a.imge.indexOf("http") > -1
            ? a.imge
            : this.api.LoginURL.concat(a.imge); //转换图片
        a.createtimes = formatDate(Date.parse(a.createtimes));
        //      let info=null;
        //     //根据回答ID查询一级评论
        //      info=await QUERYED("post","",'   WDOneComment(where: {whole: {_eq: '+data.data.WDUserQuestionAnswerRef[i].wdanswerref+'}, answer: {_eq: '+data.data.WDUserQuestionAnswerRef[i].wdanswerref+'}}) {      ancestorCommentId  answer   authorId   answers    authorImg   authorName   commentator   content    fabulous   id    whole    user   type   time   question parentCommentId  imge  isShield }');
        //      a.replyList=info.data.WDOneComment ;
        //       a.replyList.authorImg =this.api.LoginURL.concat(a.replyList.authorImg);//转换图片
        //      a.replyList.time=formatDate(Date.parse(a.replyList.time));//转换时间
        //     //alert(JSON.stringify( a.replyList))
        //        for (let j = 0; j < info.data.WDOneComment.length; j++) {
        //     //alert(info.data.WDOneComment[j].whole)
        //     // 显示二级评论
        //      let info_c=await QUERYED("post","",'   WDTwoComment(where: {whole: {_eq: '+data.data.WDUserQuestionAnswerRef[i].wdanswerref+'}, ancestorCommentId: {_eq: '+a.replyList[j].id+'}, answer: {_eq: '+data.data.WDUserQuestionAnswerRef[i].wdanswerref+'}})  {   ancestorCommentId  answer   authorId   answers    authorImg   authorName   commentator   content    fabulous   id    whole    user   type   time   question parentCommentId  imge  isShield }');
        //         a.replyList.children = info_c.data.WDTwoComment;
        //         a.replyList.children.time=formatDate(Date.parse(a.replyList.children.time));//转换时间

        //        }
        this.basiced.push(a);
      }
    },
    ////展开查看
    async quanbu1() {
      let user = this.$store.state.userinfo.id;
      if (this.shared.type == 2) {
        ///如果收费
        if (user == "" || user == null || user < 0) {
          this.$message({
            type: "warning",
            message: "您还未登录，请您先登录！"
          });
        } else {
          ///判断是否已经购买
          let ddata = await QUERYED(
            "post",
            "",
            "  WDALLBill(where: {userid: {_eq: " +
              user +
              "}, orderid: {_eq: " +
              this.problemid +
              "}}) {  time  orderNum  id   }"
          );
          if (ddata.data.WDALLBill.length > 0) {
            ////如果已经购买
            this.$message({
              type: "success",
              message: "您已经购买过！请直接查看！"
            });
            ////显示全部问题
            this.anniu = false;
            this.visabled = 1;
            this.quanbu();
          } else {
            // 如果没有购买
            ///根据登陆账号查找账户余额
            let data = await QUERYED(
              "post",
              "",
              "  PCUsers(where: {id: {_eq: " + user + "}}) {   Money  }"
            );
            this.$confirm(
              "您的账号知识币余额为" +
                data.data.PCUsers[0].Money.toFixed(2) +
                "，您确定要支付" +
                this.shared.pay +
                "知识币查看答案吗？",
              "系统提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
              }
            )
              ///确认按钮
              .then(() => {
                this.purchase(user, data.data.PCUsers[0].Money); //同意购买
                this.AcceptNumed(); //被采纳的回答采纳数修改
                // this.UserAnswer();//该被采纳者的采纳数、回答数统计
              })
              // 取消按钮
              .catch(() => {
                console.log("取消订单！");
              });
          }
        }
      } else if (this.shared.type == 1) {
        ///如果免费
        this.anniu = false;
        this.visabled = 1;
        this.quanbu(); //展开全部
      }
    },
    dealShare(event, type) {
      console.log("= " + window.location.href);

      //设置分享内容
      bShare.addEntry({
        title: this.name,
        url: window.location.href,
        summary: "这篇文章不错 分享一下~",
        pic: this.img
      });

      if (type != "more") {
        //开始分享
        bShare.share(event, type, 0);
        return false;
      } else {
        bShare.more(event);
        return false;
      }
    },
    share(stype) {
      var ftit = "";
      var flink = "";
      var lk = "";
      //获取问题标题
      //alert(this.questions[0].questionTitle)
      ftit = this.questions[0].questionTitle;
      //获取网页中内容的第一张图片
      flink = "/mobile/img/local/invitation.png";
      if (typeof flink == "undefined") {
        flink = "";
      }
      //当内容中没有图片时，设置分享图片为网站logo
      if (flink == "") {
        lk =
          "http://g.search2.alicdn.com/img/i2/126351555/TB25JDIACtYBeNjSspaXXaOOFXa_!!0-saturn_solar.jpg_220x220.jpg";
      }

      //qq空间接口的传参
      // 功能待测试线上
      if (stype == "qzone") {
        let url = "http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey";
        url = url + "?url=" + encodeURIComponent(window.location.href);
        url = url + "&title=" + this.questions[0].questionTitle;
        window.open(url);
        console.log(url, 123);

        // let url_resource = window.location.href || document.location.href;
        // if (url_resource.indexOf("localhost") > -1) {
        //   url_resource = window.location.href
        //     .split("://")[1]
        //     .replace("localhost:8080", "http://www.izhihui.net");
        // }
        // var p = {
        //   url: encodeURIComponent(url_resource),
        //   showcount: "1" /*是否显示分享总数,显示：'1'，不显示：'0' */,
        //   desc: "这篇文章不错,分享一下~~" /*默认分享理由(可选)*/,
        //   summary: "" /*分享摘要(可选)*/,
        //   title: this.name /*分享标题(可选)*/,
        //   site: "汽车氪" /*分享来源 如：腾讯网(可选)summary*/,
        //   pics: this.img /*分享图片的路径(可选)*/,
        //   style: "101",
        //   width: 199,
        //   height: 30
        // };
        // var s = [];
        // for (var i in p) {
        //   s.push(i + "=" + encodeURIComponent(p[i] || ""));
        // }
        // var target_url =
        //   "http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?" +
        //   s.join("&");
        // window.open(
        //   target_url,
        //   "qZone",
        //   "height=430, width=400,top=(window.screen.availHeight-30-iHeight)/2,left=(window.screen.availWidth-10-iWidth)/2"
        // );
      }

      //新浪微博接口的传参
      if (stype == "WB") {
        console.log("window.location.href: ", window.location.href);
        console.log("ftit: ", ftit);
        console.log("lk: ", lk);
        window.open(
          "http://service.weibo.com/share/share.php?url=" +
            encodeURIComponent(window.location.href) +
            "&title=" +
            ftit +
            "&pic=" +
            lk +
            "&appkey=2706825840"
        );
      }
      //分享到微信
      if (stype == "WX") {
        // let e = document.getElementById("qrcode");
        // e.innerHTML = "";
        // 目前是生成二维码，用户可以通过扫码进入网站
        // const wx = require("weixin-js-sdk");
        // this.$nextTick(() => {
        //   let qrcode = new QRCode("qrcode", {
        //     width: 200, // 设置宽度，单位像素
        //     height: 200, // 设置高度，单位像素
        //     text: window.location.href, // 设置二维码内容或跳转地址
        //   });
        // });
        // console.log("qrcode: ", typeof qrcode);
        console.log("share");

        // commonShare(this,this.name,window.location.href,this.img,"这篇文章不错 分享一下~")
      }
      //qq好友接口的传参
      if (stype == "QQ") {
        // window.open(
        //   "http://connect.qq.com/widget/shareqq/index.html?url=" +
        //     window.location.href +
        //     "?sharesource=qzone&title=" +
        //     ftit +
        //     "&pics=" +
        //     lk +
        //     '&summary="hgjshdhjsdhsgd"'
        // );

        // 原生js for循环处理字符串
        let url_resource = window.location.href || document.location.href;
        if (url_resource.indexOf("localhost") > -1) {
          url_resource = window.location.href
            .split("://")[1]
            .replace("localhost:8080", "http://www.izhihui.net");
        }
        var p = {
          url: url_resource /*获取URL，可加上来自分享到QQ标识，方便统计*/,
          desc:
            "来自智荟网友的分享" /*分享理由(风格应模拟用户对话),支持多分享语随机展现（使用|分隔）*/,
          title: this.name /*分享标题(可选)*/,
          summary: "这篇文章不错,分享下下~~" /*分享描述(可选)*/,
          pics: this.img /*分享图片(可选)*/,
          flash: "" /*视频地址(可选)*/,
          //commonClient : true, /*客户端嵌入标志*/
          site: "QQ分享" /*分享来源 (可选) ，如：QQ分享*/
        };

        var s = [];
        for (var i in p) {
          s.push(i + "=" + encodeURIComponent(p[i] || ""));
        }
        var target_url =
          "http://connect.qq.com/widget/shareqq/iframe_index.html?" +
          s.join("&");
        window.open(
          target_url,
          "qq",
          "height=520, width=720,top=(window.screen.availHeight-30-iHeight)/2,left=(window.screen.availWidth-10-iWidth)/2"
        );
      }
      //生成二维码给微信扫描分享
      if (stype == "wechat") {
        window.open("inc/qrcode_img.php?url=" + window.location.href + "");
      }
    },
    // 修改回答的采纳数
    async AcceptNumed() {
      // 查找回答问题被采纳的回答者ID
      let AnswerRefID = await QUERYED(
        "POST",
        "",
        "  WDUserQuestionAnswerRef(where: {adopt: {_eq: 1}, wdquestionid: {_eq: " +
          this.problemid +
          "}}) {   wdanswerref  }"
      );
      console.log(
        "采纳者ID" + AnswerRefID.data.WDUserQuestionAnswerRef[0].wdanswerref
      );
      AcceptNum(AnswerRefID.data.WDUserQuestionAnswerRef[0].wdanswerref); //传递用户ID(修改用户)
      AcceptNumTeam(AnswerRefID.data.WDUserQuestionAnswerRef[0].wdanswerref); //传递用户ID（修改团队）
    },
    // 无引用查看////单人回答和多人回答
    async Noqueto(Money, user, IDS, person, teamid) {
      ////查询购买状态（购买者）
      let ddata = await QUERYED(
        "post",
        "",
        "  WDALLBill(where: {userid: {_eq: " +
          user +
          "}}) {  time  orderNum  id   }"
      );
      // 查找回答问题被采纳的回答者ID
      let AnswerRefID = await QUERYED(
        "POST",
        "",
        "WDUserQuestionAnswerRef(where: {adopt: {_eq: 1}, wdquestionid: {_eq: " +
          IDS +
          "}}) {   user external_teamid external_problemid}"
      );
      ///根据问题ID查找发布问题者的ID
      let question = await QUERYED(
        "post",
        "",
        "WDQuestion(where: {id: {_eq: " + IDS + "}}) {   questioner  }"
      );
      // 扣费
      console.log("余额：" + Number(Money) + "付钱金额：" + this.shared.pay);
      let Moneysed = (
        Math.round((Number(Money) - Number(this.shared.pay)) * 100) / 100
      ).toFixed(2); ////扣费后保留两位小数
      // Money=Number( Money)-Number(this.viewPrice)
      console.log("用户ID：" + user + "修改价钱：" + Moneysed);
      ///修改个人的账户余额
      let info = await UPDATE(
        "post",
        "",
        "  update_User(where: {id: {_eq: " +
          user +
          "}}, _set: {Money: " +
          Moneysed +
          "}) { affected_rows  }"
      );
      if (info.data.update_User.affected_rows == 1) {
        //如果账户修改成功
        let orderNum = "";
        let num = String(formatZero(user, 7));
        /////生成订单编号
        if (ddata.data.WDALLBill.length < 1) {
          orderNum = getTimeFormat(new Date()) + num + "001";
        } else {
          for (let i = 0; i < ddata.data.WDALLBill.length; i++) {
            if (
              getTimeFormat(new Date()) ==
              getTimeFormat(ddata.data.WDALLBill[i].time)
            ) {
              let sic = ddata.data.WDALLBill[0].orderNum.substr(-3) * 1 + 1;
              orderNum =
                getTimeFormat(new Date()) + num + String(formatZero(sic, 3));
              break;
            } else {
              orderNum = getTimeFormat(new Date()) + num + "001";
            }
          }
        }
        console.log(
          "时间11" + new Date(),
          "用户ID" + user,
          "订单编号" + orderNum,
          "订单ID" + this.problemid,
          "价钱" + this.shared.pay
        );
        ///我的扣费账单 ----生成账单
        let data1 = await INSERT(
          "post",
          "",
          '  insert_bill(objects: {type: 1, state: 0, time: "' +
            new Date() +
            '", userid: ' +
            user +
            ', orderNum: "' +
            orderNum +
            '", orderid: ' +
            IDS +
            ', Price: "' +
            this.shared.pay +
            '", evaluate: "",classification:1}) {affected_rows    returning {    id  }  }'
        );
        if (data1.data.insert_bill.affected_rows == 1) {
          console.log("查看问题者的账单生成成功！");
          // 查找该问题的分成比例
          let FCBL = await QUERYED(
            "POST",
            "",
            "WDQuestion(where:{id: {_eq:" +
              IDS +
              "}}) {questionerPercentage answererPercentage}"
          );
          // let release=this.viewPrice*Number(FCBL.data.WDQuestion[0].questionerPercentage)/100;  // 问题发布者所需获得的价钱
          let release = (
            Math.round(
              ((Number(this.shared.pay) *
                Number(FCBL.data.WDQuestion[0].questionerPercentage)) /
                100) *
                100
            ) / 100
          ).toFixed(2); ////保留两位小数
          // release=(Math.round((Number(this.viewPrice)*Number(FCBL.data.WDQuestion[0].questionerPercentage)/100)*100)/100).toFixed(2);////保留两位小数
          // let answer=this.viewPrice*Number(FCBL.data.WDQuestion[0].answererPercentage)/100;//问题回答者所需获得的价钱
          let answer = (
            Math.round(
              ((Number(this.shared.pay) *
                Number(FCBL.data.WDQuestion[0].answererPercentage)) /
                100) *
                100
            ) / 100
          ).toFixed(2); ////保留两位小数
          // answer=(Math.round((Number(this.viewPrice)*Number(FCBL.data.WDQuestion[0].answererPercentage)/100)*100)/100).toFixed(2);////保留两位小数
          console.log("发布者价钱：" + release + "采纳者价钱：" + answer);
          ///查找发布问题者的知识币余额
          let Moenys = await QUERYED(
            "POST",
            "",
            "  PCUsers(where: {id: {_eq: " +
              question.data.WDQuestion[0].questioner +
              "}}) {   Money  }"
          );
          Moenys.data.PCUsers[0].Money =
            Number(Moenys.data.PCUsers[0].Money) + Number(release); ///发布者的账户余额（账户添加价钱成功）
          //修改发布问题者的账户余额
          let updateMoeny = await UPDATE(
            "POST",
            "",
            "  update_User(where: {id: {_eq: " +
              question.data.WDQuestion[0].questioner +
              "}}, _set: {Money: " +
              Moenys.data.PCUsers[0].Money +
              "}) {  affected_rows  }"
          );
          if (updateMoeny.data.update_User.affected_rows == 1) {
            //////////////////问题发布者添加一条账单
            ////问题发布者查询购买状态
            let ddata1 = await QUERYED(
              "post",
              "",
              "  WDALLBill(where: {userid: {_eq: " +
                question.data.WDQuestion[0].questioner +
                "}}) {  time  orderNum  id   }"
            );
            //  生成回答问题者的订单编号
            let orderNum1 = "";
            let num1 = String(
              formatZero(question.data.WDQuestion[0].questioner, 7)
            );
            /////生成订单编号
            if (ddata1.data.WDALLBill.length < 1) {
              orderNum1 = getTimeFormat(new Date()) + num1 + "001";
            } else {
              for (let i = 0; i < ddata1.data.WDALLBill.length; i++) {
                if (
                  getTimeFormat(new Date()) ==
                  getTimeFormat(ddata1.data.WDALLBill[i].time)
                ) {
                  let sic =
                    ddata1.data.WDALLBill[0].orderNum.substr(-3) * 1 + 1;
                  orderNum1 =
                    getTimeFormat(new Date()) +
                    num1 +
                    String(formatZero(sic, 3));
                } else {
                  orderNum1 = getTimeFormat(new Date()) + num1 + "001";
                }
              }
            }
            console.log(
              "时间" + new Date(),
              "用户ID" + question.data.WDQuestion[0].questioner,
              "订单编号" + orderNum1,
              "订单ID" + IDS,
              "价钱" + release
            );
            ////给问题发布者账单加入一条收入数据
            let data2 = await INSERT(
              "post",
              "",
              '  insert_bill(objects: {type: 1, state: 0, time: "' +
                new Date() +
                '", userid: ' +
                question.data.WDQuestion[0].questioner +
                ', orderNum: "' +
                orderNum1 +
                '", orderid: ' +
                IDS +
                ', Price: "' +
                release +
                '", evaluate: "",classification:0}) {affected_rows    returning {    id  }  }'
            );
            console.log(
              "发布者账单存入成功！" + data2.data.insert_bill.returning[0].id
            );
            ///通过问题ID查询问题内容
            let questions = await QUERYED(
              "POST",
              "",
              "  WDQuestion(where: {id: {_eq: " + IDS + "}}) { questionTitle  }"
            );
            // console.log('收到消息的ID'+question.data.WDQuestion[0].questioner,"时间"+new Date()+"发消息的ID"+user,"问题"+questions.data.WDQuestion[0].questionTitle,"发布者价钱"+release,"采纳者价钱"+answer)
            ////给问题发布者发一条消息通知他查看
            let seeData = await INSERT(
              "post",
              "",
              "  insert_MessageNotification(objects: {type: 1, userid: " +
                question.data.WDQuestion[0].questioner +
                ', creatime: "' +
                new Date() +
                '", beuserid:' +
                user +
                ',message:"' +
                questions.data.WDQuestion[0].questionTitle +
                '",bill : ' +
                release +
                ",state :2}) { affected_rows  }"
            );
            if (seeData.data.insert_MessageNotification.affected_rows == 1) {
              /////问题发布者的消息发送成功！
              // 判断是单人回答还是多人回答
              if (person == 0) {
                ///如果是单人回答
                console.log(
                  "采纳者用户ID：" +
                    AnswerRefID.data.WDUserQuestionAnswerRef[0].user
                );
                /////////计算回答者的应获得的
                this.Single(
                  AnswerRefID.data.WDUserQuestionAnswerRef[0].user,
                  answer,
                  IDS,
                  questions.data.WDQuestion[0].questionTitle,
                  user
                ); //调用单人回答
              } else if (person == 1) {
                //如果是多人回答
                ////通过外部团队ID查找回答的用户ID
                console.log(
                  "外部团队ID：" +
                    AnswerRefID.data.WDUserQuestionAnswerRef[0]
                      .external_teamid +
                    "外部问题ID：" +
                    AnswerRefID.data.WDUserQuestionAnswerRef[0]
                      .external_problemid
                );
                let people_answer = await QUERYED(
                  "POST",
                  "",
                  "people_answer(where: {team_id : {_eq: " +
                    AnswerRefID.data.WDUserQuestionAnswerRef[0]
                      .external_teamid +
                    "},ques_id  : {_eq: " +
                    AnswerRefID.data.WDUserQuestionAnswerRef[0]
                      .external_problemid +
                    "}}) {   id  user_id  }"
                );
                //////每个人都应该获得的价钱
                let External_price = (
                  Math.round(
                    (Number(answer) /
                      Number(people_answer.data.people_answer.length)) *
                      100
                  ) / 100
                ).toFixed(2);
                //  Number(answer)/Number(people_answer.data.people_answer.length);
                for (
                  let i = 0;
                  i < people_answer.data.people_answer.length;
                  i++
                ) {
                  ///循环扣费
                  console.log(
                    "多人团队用户ID：" +
                      people_answer.data.people_answer[i].user_id
                  );
                  ///通过用户ID查找该用户的账户余额
                  let MoenyAdo = await QUERYED(
                    "POST",
                    "",
                    "  PCUsers(where: {id: {_eq: " +
                      people_answer.data.people_answer[i].user_id +
                      "}}) {   Money  }"
                  );
                  ////修改该用户的账户余额
                  MoenyAdo.data.PCUsers[0].Money =
                    Number(MoenyAdo.data.PCUsers[0].Money) +
                    Number(External_price);
                  ////修改返回数据库
                  let updateMoeny1 = await UPDATE(
                    "POST",
                    "",
                    "  update_User(where: {id: {_eq: " +
                      people_answer.data.people_answer[i].user_id +
                      "}}, _set: {Money: " +
                      MoenyAdo.data.PCUsers[0].Money +
                      "}) {  affected_rows  }"
                  );
                  if (updateMoeny1.data.update_User.affected_rows == 1) {
                    //修改成功！
                    //////////////////////////生成订单编号//////////////////////////
                    ////采纳者的  people_answer.data.people_answer[i].user_id（采纳者id）
                    let ddata1 = await QUERYED(
                      "post",
                      "",
                      "  WDALLBill(where: {userid: {_eq: " +
                        people_answer.data.people_answer[i].user_id +
                        "}}) {  time  orderNum  id   }"
                    );
                    //  生成回答问题者的订单编号
                    let orderNum1 = "";
                    let num1 = String(
                      formatZero(people_answer.data.people_answer[i].user_id, 7)
                    );
                    /////生成订单编号
                    if (ddata1.data.WDALLBill.length < 1) {
                      orderNum1 = getTimeFormat(new Date()) + num1 + "001";
                    } else {
                      for (let i = 0; i < ddata1.data.WDALLBill.length; i++) {
                        if (
                          getTimeFormat(new Date()) ==
                          getTimeFormat(ddata1.data.WDALLBill[i].time)
                        ) {
                          let sic =
                            ddata1.data.WDALLBill[0].orderNum.substr(-3) * 1 +
                            1;
                          orderNum1 =
                            getTimeFormat(new Date()) +
                            num1 +
                            String(formatZero(sic, 3));
                        } else {
                          orderNum1 = getTimeFormat(new Date()) + num1 + "001";
                        }
                      }
                    }
                    console.log(
                      "时间" + new Date(),
                      "用户ID" + people_answer.data.people_answer[i].user_id,
                      "订单编号" + orderNum1,
                      "订单ID" + IDS,
                      "价钱" + External_price
                    );
                    ////给采纳者账单加入一条收入数据
                    let data2 = await INSERT(
                      "post",
                      "",
                      '  insert_bill(objects: {type: 1, state: 0, time: "' +
                        new Date() +
                        '", userid: ' +
                        people_answer.data.people_answer[i].user_id +
                        ', orderNum: "' +
                        orderNum1 +
                        '", orderid: ' +
                        IDS +
                        ', Price: "' +
                        External_price +
                        '", evaluate: "",classification:0}) {affected_rows    returning {    id  }  }'
                    );
                    console.log(
                      "采纳者账单存入成功！" +
                        data2.data.insert_bill.returning[0].id
                    );
                    ///通过问题ID查询问题内容
                    let questions = await QUERYED(
                      "POST",
                      "",
                      "  WDQuestion(where: {id: {_eq: " +
                        IDS +
                        "}}) { questionTitle  }"
                    );
                    // console.log('收到消息的ID'+question.data.WDQuestion[0].questioner,"时间"+new Date()+"发消息的ID"+user,"问题"+questions.data.WDQuestion[0].questionTitle,"发布者价钱"+release,"采纳者价钱"+answer)
                    ////给采纳者发一条消息通知他查看
                    let seeData = await INSERT(
                      "post",
                      "",
                      "  insert_MessageNotification(objects: {type: 1, userid: " +
                        people_answer.data.people_answer[i].user_id +
                        ', creatime: "' +
                        new Date() +
                        '", beuserid:' +
                        user +
                        ',message:"' +
                        questions.data.WDQuestion[0].questionTitle +
                        '",bill : ' +
                        External_price +
                        ",state :2}) { affected_rows  }"
                    );
                    if (
                      seeData.data.insert_MessageNotification.affected_rows == 1
                    ) {
                      console.log("消息发送成功！");
                    }
                  }
                }
                this.$message({
                  type: "success",
                  message: "购买成功！"
                });
                this.anniu = false;
                this.visabled = 1;
                this.quanbu();
                this.loading = false; //隐藏正在加载中
              }
            }
          }
        }
      } else {
        ///生成账单
        let data1 = await INSERT(
          "post",
          "",
          '  insert_bill(objects: {type: 1, state: 3, time: "' +
            new Date() +
            '", userid: ' +
            user +
            ', orderNum: "' +
            orderNum +
            '", orderid: ' +
            IDS +
            ', Price: "' +
            this.viewPrice +
            '", evaluate: ""}) {affected_rows  }'
        );
        console.log(data1.data.insert_bill.affected_rows);
        this.$message({
          type: "success",
          message: "购买失败！"
        });
      }
    },
    ////单人回答、、采纳者单独的扣费/消息/账单信息
    async Single(AnswerRefIDuser, answer, IDS, questionTitle, user) {
      ///根据回答问题的ID查找该用户的知识币余额
      let MoenyAdo = await QUERYED(
        "POST",
        "",
        "  PCUsers(where: {id: {_eq: " + AnswerRefIDuser + "}}) {   Money  }"
      );
      MoenyAdo.data.PCUsers[0].Money =
        Number(MoenyAdo.data.PCUsers[0].Money) + Number(answer); ///采纳者的账户余额
      console.log("采纳者账户余额：" + MoenyAdo.data.PCUsers[0].Money);
      ///修改获得采纳者的账户余额
      let updateMoeny1 = await UPDATE(
        "POST",
        "",
        "  update_User(where: {id: {_eq: " +
          AnswerRefIDuser +
          "}}, _set: {Money: " +
          MoenyAdo.data.PCUsers[0].Money +
          "}) {  affected_rows  }"
      );
      if (updateMoeny1.data.update_User.affected_rows == 1) {
        //////////////////采纳者添加一条账单
        ////采纳者的  AnswerRefIDuser（采纳者id）
        let ddata1 = await QUERYED(
          "post",
          "",
          "  WDALLBill(where: {userid: {_eq: " +
            AnswerRefIDuser +
            "}}) {  time  orderNum  id   }"
        );
        //  生成回答问题者的订单编号
        let orderNum1 = "";
        let num1 = String(formatZero(AnswerRefIDuser, 7));
        /////生成订单编号
        if (ddata1.data.WDALLBill.length < 1) {
          orderNum1 = getTimeFormat(new Date()) + num1 + "001";
        } else {
          for (let i = 0; i < ddata1.data.WDALLBill.length; i++) {
            if (
              getTimeFormat(new Date()) ==
              getTimeFormat(ddata1.data.WDALLBill[i].time)
            ) {
              let sic = ddata1.data.WDALLBill[0].orderNum.substr(-3) * 1 + 1;
              orderNum1 =
                getTimeFormat(new Date()) + num1 + String(formatZero(sic, 3));
            } else {
              orderNum1 = getTimeFormat(new Date()) + num1 + "001";
            }
          }
        }
        console.log(
          "时间" + new Date(),
          "用户ID" + AnswerRefIDuser,
          "订单编号" + orderNum1,
          "订单ID" + IDS,
          "价钱" + answer
        );
        ////给采纳者账单加入一条收入数据
        let data2 = await INSERT(
          "post",
          "",
          '  insert_bill(objects: {type: 1, state: 0, time: "' +
            new Date() +
            '", userid: ' +
            AnswerRefIDuser +
            ', orderNum: "' +
            orderNum1 +
            '", orderid: ' +
            IDS +
            ', Price: "' +
            answer +
            '", evaluate: "",classification:0}) {affected_rows    returning {    id  }  }'
        );
        console.log(
          "采纳者账单存入成功！" + data2.data.insert_bill.returning[0].id
        );
        ///通过问题ID查询问题内容
        // let questions=await QUERYED("POST","",'  WDQuestion(where: {id: {_eq: '+IDS+'}}) { questionTitle  }');
        // console.log('收到消息的ID'+question.data.WDQuestion[0].questioner,"时间"+new Date()+"发消息的ID"+user,"问题"+questions.data.WDQuestion[0].questionTitle,"发布者价钱"+release,"采纳者价钱"+answer)
        ////给采纳者发一条消息通知他查看
        let seeData = await INSERT(
          "post",
          "",
          "  insert_MessageNotification(objects: {type: 1, userid: " +
            AnswerRefIDuser +
            ', creatime: "' +
            new Date() +
            '", beuserid:' +
            user +
            ',message:"' +
            questionTitle +
            '",bill : ' +
            answer +
            ",state :2}) { affected_rows  }"
        );
        if (seeData.data.insert_MessageNotification.affected_rows == 1) {
          this.$message({
            type: "success",
            message: "购买成功！"
          });
          this.anniu = false;
          this.visabled = 1;
          this.quanbu();
          this.loading = false; //隐藏正在加载中
        }
      }
    },
    //   ///计算团队的回答数和采纳数
    //   async UserAnswer(){
    //   // 查找回答问题被采纳的回答者ID
    //     let AnswerRefID=await QUERYED("POST","",'  WDUserQuestionAnswerRef(where: {adopt: {_eq: 1}, wdquestionid: {_eq: '+this.problemid+'}}) {   user  }');
    //     //////////// 统计修改被采纳者的回答数和采纳数
    //     //////查询被采纳者的回答数
    //     // let data = await QUERYED(  "post",  "",   " WDUserQuestionAnswerRef_aggregate(where: {id: {_eq: " + AnswerRefID.data.WDUserQuestionAnswerRef[0].user + "}}) { aggregate {  count(columns: wdanswerref)    }  }" );
    //     /////查询被采纳者的采纳数
    //     let data1 = await QUERYED(  "post",""," WDUserQuestionAnswerRef_aggregate(where: {id: {_eq: "+ AnswerRefID.data.WDUserQuestionAnswerRef[0].user+ "}, adopt: {_eq: 1}}) {  aggregate {  count(columns: adopt)   } }");
    //   // 被采纳者的采纳数加一
    //   let CN=Number( data1.data.WDUserQuestionAnswerRef_aggregate.aggregate.count)+1;
    //   //  修改被采纳者的 采纳数
    //   let update_User=await UPDATE("post","",'  update_User(where: {id: {_eq: '+AnswerRefID.data.WDUserQuestionAnswerRef[0].user+'}}, _set: {wdAcceptNum: '+CN+'}) {  affected_rows  }')
    //   if(update_User.data.update_User.affected_rows==1)
    //   {
    //     ///判断该用户是否加入了团队
    //     let TeamUser=await QUERYED("post","",'    WDTeamMember(where: {userid: {_eq: '+AnswerRefID.data.WDUserQuestionAnswerRef[0].user+'}}) { team  }');
    //     if(TeamUser.data.WDTeamMember.length>0)
    //     {//如果加入了团队
    //        for(let i = 0; i < TeamUser.data.WDTeamMember.length; i++){
    //         //  ///通过团队ID统计该团队的回答数
    //         //     let dataAn = await QUERYED(  "post",    "",  ' WDMemberTeamWdAnswerRef_aggregate(where: {id: {_eq: '+TeamUser.data.WDTeamMember[i].team+'}}) { aggregate {   count(columns: wdteammemberid)  }  }' );
    //         ///通过ID统计该团队的采纳数
    //          let dataAc = await QUERYED(  "post",    "",  ' WDMemberTeamWdAnswerRef_aggregate(where: {id: {_eq: '+TeamUser.data.WDTeamMember[i].team+'}}) { aggregate {   count(columns: AcceptNum)  }  }' );
    //         let TDcn=Number(dataAc.data.WDMemberTeamWdAnswerRef_aggregate.aggregate.count)+1;
    //         // 修改团队的采纳数
    //          let update_UP=await UPDATE("post","",'  update_WdTeam(where: {id: {_eq: '+TeamUser.data.WDTeamMember[i].team+'}}, _set: {acceptNum: '+TDcn+'}) { affected_rows  }')
    //       if(update_UP.data.update_WdTeam.affected_rows==1){
    //         console.log("修改团队采纳数成功！")
    //       }
    //   }

    //     }
    //   }
    // },
    ///购买事件
    async purchase(user, Money) {
      ////查询购买状态
      let ddata = await QUERYED(
        "post",
        "",
        "  WDALLBill(where: {userid: {_eq: " +
          user +
          "}}) {  time  orderNum  id   }"
      );
      ///如果账户余额不足
      if (Money < this.shared.pay) {
        ////给出余额不足的警告
        this.$alert(
          "您的账户余额不足，请你先到个人中心充值后在购买！",
          "系统提示：",
          {
            confirmButtonText: "确定",
            showClose: false,
            distinguishCancelAndClose: true
          }
        );
        this.anniu = true;
      } else {
        //  如果余额充足
        // 查找回答问题被采纳的回答者ID
        let AnswerRefID = await QUERYED(
          "POST",
          "",
          "  WDUserQuestionAnswerRef(where: {adopt: {_eq: 1}, wdquestionid: {_eq: " +
            this.problemid +
            "}}) {   user  }"
        );
        ///根据问题ID查找发布问题者的ID
        let question = await QUERYED(
          "post",
          "",
          "  WDQuestion(where: {id: {_eq: " +
            this.problemid +
            "}}) {   questioner  }"
        );
        if (AnswerRefID.data.WDUserQuestionAnswerRef[0].user == user) {
          //如果登陆账号是被采纳者
          ////给出余额不足的警告
          this.$alert("您是被采纳者无需购买，可直接查看！", "系统提示：", {
            confirmButtonText: "确定",
            showClose: false,
            distinguishCancelAndClose: true
          });
          this.quanbu(); ///展开全部
          this.visabled = 1;
          this.anniu = false;
        } else {
          ///如果是第三方查看（第一层）
          // 判断该回答有无被引用
          let quetos = await QUERYED(
            "POST",
            "",
            "WdAnswerRef(where: {question: {_eq:" +
              this.problemid +
              "},adopt: {_eq: 1}}) {   id person quetoType quetoAnswer external_teamid  quetoPloblem  }"
          );
          if (quetos.data.WdAnswerRef[0].quetoType == 0) {
            //如果没有被引用
            // 包括多人和单人问答
            this.loading = true; ///显示正在加载中
            this.Noqueto(
              Money,
              user,
              this.problemid,
              quetos.data.WdAnswerRef[0].person,
              quetos.data.WdAnswerRef[0].external_teamid
            ); ///无引用（单人、多人）支付
            this.loading = false; ///隐藏正在加载中
          } else {
            ///如果被引用了
            this.loading = true; ///显示正在加载中
            // 通过问题ID查找判断引用的问题是否是查看分成
            let answer = await QUERYED(
              "POST",
              "",
              "WDQuestion(where: {id: {_eq:" +
                quetos.data.WdAnswerRef[0].quetoPloblem +
                "}}) {  id     isViewFree  }"
            );
            if (answer.data.WDQuestion[0] == undefined) {
              this.loading = false; //隐藏正在加载中
              alert("引用问题已被删除!");
            } else {
              if (answer.data.WDQuestion[0].isViewFree == 1) {
                ///如果引用免费的
                this.Noqueto(
                  Money,
                  user,
                  this.problemid,
                  quetos.data.WdAnswerRef[0].person,
                  quetos.data.WdAnswerRef[0].external_teamid
                ); ///无引用（单人、多人）支付
              } else if (answer.data.WDQuestion[0].isViewFree != 1) {
                ///引用收费答案
                /////////////////////先给登陆账户扣除费用///////
                ///查找回答ID
                let AnswerRefIDe = await QUERYED(
                  "POST",
                  "",
                  "WDUserQuestionAnswerRef(where: {adopt: {_eq: 1}, wdquestionid: {_eq: " +
                    this.problemid +
                    "}}) {   wdanswerref}"
                );
                console.log(
                  "回答ID:" +
                    AnswerRefIDe.data.WDUserQuestionAnswerRef[0].wdanswerref
                );
                //////访问后台计算引用收费问题quesId,answerId，time
                let DATE = new Date();
                //////访问后台计算引用收费问题quesId,answerId，time
                axios({
                  method: "post",
                  url: this.api.LoginURL.concat() + "/common/share",
                  params: {
                    quesId: this.problemid, //问题ID
                    answerId:
                      AnswerRefIDe.data.WDUserQuestionAnswerRef[0].wdanswerref, //回答ID
                    time: String(DATE),
                    userId: user //登陆用户
                  },
                  headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("Authorization")
                  }
                })
                  .then(response => {
                    console.log("加载成功：" + JSON.stringify(response.data));
                    if (response.data.respCode == 0) {
                      this.$message({
                        type: "success",
                        message: "购买成功！"
                      });
                      this.quanbu(); ///展开全部
                      this.loading = false; //隐藏正在加载中
                      this.visabled = 1;
                      this.anniu = false; ///展开全部
                    } else if (response.data.respCode == -1) {
                      //alert("服务器错误！请重新刷新！");
                      this.$message({
                        message: "服务器错误！请重新刷新！",
                        type: "warning"
                      });
                    } else if (
                      response.data.respCode == -2 ||
                      response.data.respCode == -9
                    ) {
                      //alert("登录超时，请重新登录！");
                      this.$message({
                        message: "登录超时，请重新登录！",
                        type: "warning"
                      });
                    }
                  })
                  .catch(function(error) {
                    console.log("错误" + error);
                    //alert("加载失败,请重新刷新！");
                    this.$message({
                      message: "加载失败,请重新刷新！",
                      type: "warning"
                    });
                  });
              }
            }
          }
        }
      }
    }
  },
  components: {
    "V-Question": Question,
    // 'V-EditorBar':EditorBar,
    "V-WaitAnswer": WaitAnswer,
    "V-Details": Details,
    "V-Quote": Quote,
    "V-Team": Team,
    "V-Loading": Loading //正在加载中
  }
};
</script>
<style scoped>
.search-icon {
  width: 97%;
  height: 300px;
  font-size: 15px;
  padding: 10px;
  resize: none;
  background-color: rgb(250, 250, 250);
}
.uppp {
  height: 50px;
  padding: 20px;
}
.uppp > span {
  display: inline-block;
}
.VUF {
  width: 31%;
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}
/* 附件 */
.uplid {
  position: relative;
}
/* 视频 */
.video {
  vertical-align: top;
  position: relative;
  display: inline-block;
  margin-right: 20px;
}
.picture {
  vertical-align: top;
  display: inline-block;
}
.write-answer {
  margin: 20px auto;
  width: 1134px;
}
.write-answer .left > div {
  padding: 10px;
}
.write-answer .left > .answer {
  margin-top: 10px;
  padding-top: 30px;
  background-color: #ffff;
}
.write-answer .left {
  display: inline-block;
  vertical-align: top;
  width: 750px;
}
.write-answer .right {
  display: inline-block;
  margin-left: 20px;
  vertical-align: top;
  width: 350px;
}
.left .question {
  background-color: #ffff;
}
.share-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-size: 15px 15px;
  background-image: url("~@/assets/icon/invite.png");
  background-repeat: no-repeat;
  margin-right: 3px;
}
.invite-icon {
  display: inline-block;
  width: 18px;
  height: 16px;
  background-image: url("~@/assets/icon/invite.png");
}
.q-btns {
  margin-top: 20px;
}
.q-btns .share {
  margin-left: 30px;
  height: 30px;
  line-height: 30px;
  vertical-align: top;
  display: inline-block;
  font-size: 13px;
  position: relative;
  top: 7.5px;
}
.q-btns .collect {
  height: 45px;
  line-height: 45px;
  vertical-align: top;
  display: inline-block;
  margin-left: 60px;
  font-size: 13px;
  width: 80px;
}
.collect > span:hover {
  color: 0084ff;
}
.show-hand {
  cursor: pointer;
}
.q-btns .answer-btn,
.q-btns .answer-btn1 {
  width: 100px;
  display: inline-block;
}
.answer-btn a {
  width: 78px;
  height: 28px;
  background: rgba(0, 132, 255, 1);
  border-radius: 5px;
  color: #ffffff;
  text-decoration: none;
  line-height: 28px;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  padding: 5px 10px;
}
.answer-btn1 a {
  width: 78px;
  height: 28px;
  background: #ced0d0;
  border-radius: 5px;
  color: #ffffff;
  text-decoration: none;
  line-height: 28px;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  padding: 5px 10px;
}
.answer-btn .block,
.answer-btn1 .block {
  display: inline-block;
  vertical-align: bottom;
}

.write-icon {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-image: url("~@/assets/icon/write.png");
  background-size: 13px 13px;
  background-repeat: no-repeat;
  position: relative;
  top: -2px;
}
.q-setting {
  width: 100%;
}
.q-setting .setting {
  line-height: 30px;
}
.q-setting .answer-btn {
  display: inline-block;
  width: 100px;
}
.q-setting .answer-btn1 {
  display: inline-block;
  width: 100px;
}
.setting .bottom-block {
  bottom: 0px;
  margin-top: 20px;
  width: 100%;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  background-color: #f0f1f1;
}
.q-setting .setting {
  position: relative;
  display: inline-block;
  width: 94.5%;
  vertical-align: bottom;
}
.bottom-block .condition-style {
  color: rgb(133, 130, 130);
  margin-left: 10px;
}
.up-file {
  display: inline-block;
  width: 58px;
  height: 51px;
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 3px;
  background-image: url(~@/assets/icon/add-file.png);
  background-size: 25px 25px;
  background-repeat: no-repeat;
  background-position-x: 14px;
  background-position-y: 10px;
  cursor: pointer;
}
.up-file:hover {
  border-color: rgb(241, 233, 233);
  background-color: rgb(241, 233, 233);
}
.remove-file {
  color: red;
  cursor: pointer;
  margin-right: 20px;
  line-height: 23px;
}
.remove-file:hover {
  color: rosybrown;
  font-size: 20px;
}
.details {
  width: 730px;
  margin-top: 10px;
  background-color: white;
  display: inline-block;
}
textarea:focus {
  outline: none;
  border: 1px solid #9ac1f5;
  box-shadow: 1px 2px 13px 0px rgba(11, 97, 226, 0.44);
}
el-tooltip:focus {
  outline: none;
}
input:focus {
  outline: none;
  border: 1px solid #9ac1f5;
  box-shadow: 1px 2px 13px 0px rgba(11, 97, 226, 0.44);
}
.up-video {
  display: inline-block;
  width: 58px;
  height: 51px;
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 3px;
  background-image: url(~@/assets/icon/add-video.png);
  background-size: 25px 25px;
  background-repeat: no-repeat;
  background-position-x: 16px;
  background-position-y: 13px;
  cursor: pointer;
  background-color: white;
}
.up-video:hover,
.up-img:hover {
  background-color: rgba(210, 210, 210, 1);
}
.quote:hover {
  border-radius: 5%;
  background-color: rgba(210, 210, 210, 1);
}
.detesed {
  width: 100%;
  height: 22px;
  display: inline-block;
  text-align: center;
}
.share ul li {
  width: 50%;
  float: left;
  text-align: center;
  position: relative;
  top: -5px;
}
.share ul li span {
  cursor: pointer;
}
.detes {
  width: 20px;
  height: 20px;
  text-align: center;
  display: inline-block;
  background-image: url("../../../../assets/icon/delete.png");
  background-repeat: no-repeat;
  background-size: 16px 16px;
  position: relative;
  top: 7px;
  cursor: pointer;
}
.deteseds {
  width: 20px;
  height: 20px;
  text-align: center;
  display: inline-block;
  background-image: url("../../../../assets/icon/delete.png");
  background-repeat: no-repeat;
  background-size: 13px 13px;
  position: relative;
  left: 0px;
  top: 7px;
  cursor: pointer;
  margin-left: 10px;
}
.putr {
  width: 100%;
  height: 130px;
}
.up-img {
  display: inline-block;
  width: 58px;
  height: 51px;
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 3px;
  background-image: url(~@/assets/icon/add-img.png);
  background-size: 27px 27px;
  background-repeat: no-repeat;
  background-position-x: 14px;
  background-position-y: 10px;
  background-color: white;
  cursor: pointer;
  margin-right: 20px;
  vertical-align: top;
}
.tip-info {
  display: inline-block;
  margin-left: 230px;
  font-size: 12px;
  line-height: 24px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(79, 79, 79, 1);
}
.pre-list {
  display: inline-block;
  margin-left: 5px;
  width: 55px;
  height: 80px;
  position: relative;
  top: 16px;
}
.one-v {
  border: 1px solid rgba(90, 90, 90, 1);
}
.one-btn {
  text-align: center;
  margin-left: 0px;
  margin-bottom: 10px;
  font-size: 14px;
  color: rgba(150, 150, 150, 1);
  cursor: pointer;
}
.pre-list .one-btn:hover {
  color: red;
}
.pre-list:first-of-type {
  margin-left: 30px;
}
.qu:hover {
  color: red;
  cursor: pointer;
}
.pic_list_box {
  display: flex;
}
.upload_btn {
  width: 58px;
  height: 51px;
  padding-left: 15px;
}
.pic_list {
  display: flex;
}
.head {
  width: 96%;
  height: 30px;
  float: left;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #c0c0c0;
  margin-left: 17px;
}
.time {
  font-size: 15px;
  font-weight: bold;
}
/* //收藏按钮 */
.collect1-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  background-image: url("../../../../assets/icon/Collectioned1.png");
  background-size: 15px 15px;
  background-repeat: no-repeat;
  position: relative;
  left: 5px;
  margin-right: 5px;
}
.collect2-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  background-image: url("../../../../assets/icon/Collection.png");
  background-size: 15px 15px;
  background-repeat: no-repeat;
  position: relative;
  left: 5px;
  margin-right: 5px;
}
.hover:hover {
  color: #0084ff;
}
.open {
  width: 100%;
  text-align: center;
  color: #0084ff;
  font-size: 13px;
  cursor: pointer;
}
.quote {
  text-align: right;
  float: right;
  cursor: pointer;
}
/* 引用 */
.yinY {
  display: inline-block;
  background-image: url("../../../../assets/Yin1 (1).png");
  width: 58px;
  height: 51px;
  background-repeat: no-repeat;
  background-position-x: 9px;
  background-position-y: 7px;
  background-size: 38px 35px;
  border: 1px solid #dcdcdc;
  border-radius: 5%;
}
/* 取消引用 */
.yin1 {
  display: inline-block;
  background-image: url("../../../../assets/quxiao.png");
  width: 58px;
  height: 51px;
  background-repeat: no-repeat;
  background-position-x: 9px;
  background-position-y: 7px;
  background-size: 38px 35px;
  border: 1px solid #dcdcdc;
  border-radius: 5%;
}
.down {
  width: 25px;
  height: 25px;
  display: inline-block;
  background-image: url("../../../../assets/icon/down2.png");
  background-repeat: no-repeat;
  position: relative;
  top: 15px;
  left: 2px;
}
.Echo {
  width: 100%;
}
.score {
  width: 15px;
}
.scoreS {
  width: 15px;
  position: relative;
  top: 4px;
  margin-right: 1px;
}
.margin_top {
  margin-top: 50px;
}
.wait-head {
  padding: 10px;
  width: 330px;
  display: inline-block;
  background-color: rgb(255, 255, 255);
  /* position: relative; */
  /* border-top: 1px solid #B5B5B5; */
  /* margin-top: 20px; */
  /* border: 1px solid red; */
  /* display: inline-block; */
}
.wait-head .title {
  display: inline-block;
  font-weight: bold;
  font-size: 17px;
  /* position: absolute;
  left: 0; */
  vertical-align: text-top;
  /* background-color: chartreuse; */
}
</style>
