<template>
  <div class="wait-answer">
    <div class="wait-items">
      <div class="wait-item" v-for="(item,index) in problem" :key="index">
        <div class="q-info">
          <span class="qs-title">{{item.title}}</span>
          <span class="q-has-img" v-if="item.img"></span>
        </div>
        <div class="q-btn" @click="see(item.id)">
          <span class="write-icon"></span>
          <span>我来答</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DELETE, QUERY, INSERT, UPDATE } from "@/services/dao.js";
export default {
  data() {
    return {
      //   problem:[],
      datacontent: [], // 用来存放每次点击换一批出来的三个对象
      num2: "", // 用来放三个随机数
      arr: [] // 用来放三个随机数的数组，用来循环
    };
  },
  props: {
    problem: {
      type: Array,
      required: true
    }
  },
  //    mounted () { // 页面一加载的时候先出来三个对象的内容
  //       for (var i = 0; i < 3; i++) {
  //         this.datacontent.push(this.problem[i])
  //       }
  //     },
  created() {},
  methods: {
    //    跳转
    async see(id) {
      this.$emit("seee", id);
    },

    async change() {}
  }
};
</script>

<style scoped>
.wait-answer {
  background-color: #ffffff;
  padding: 10px;
  /* padding-top: 20px; */
}
.wait-answer .wait-head {
  position: relative;
  /* border-top: 1px solid #B5B5B5; */
  /* margin-top: 20px; */
  /* border: 1px solid red; */
  /* display: inline-block; */
}
.wait-answer .wait-head .title {
  display: inline-block;
  font-weight: bold;
  font-size: 17px;
  position: absolute;
  left: 0;
  vertical-align: text-top;
}
.wait-answer .wait-head .refresh > * {
  vertical-align: top;
}
.wait-answer .wait-head .refresh {
  display: inline-block;
  position: absolute;
  right: 0;
  vertical-align: text-top;
  text-decoration: none;
}
.refresh-icon {
  display: inline-block;
  height: 22px;
  width: 22px;
  background-image: url("~@/assets/icon/change2.png");
  background-repeat: no-repeat;
  position: relative;
  margin-right: 3px;
  border: 0;
}
.wait-answer .wait-head .refresh {
  font-size: 12px;
  color: darkgray;
}
.wait-items {
  /* margin-top: 40px; */
  font-size: 14px;
  /* border: 1px solid red; */
}
.wait-items .wait-item > div {
  padding: 5px;
}
.wait-items .wait-item .q-info {
  position: relative;
}
.wait-items .wait-item .q-info .q-has-img {
  position: absolute;
  right: 0;
}
.wait-item .q-btn {
  text-align: right;
  color: #0084ff;
  font-size: 14px;
  line-height: 20px;
  height: 20px;
  cursor: pointer;
  /* border: 1px solid red; */
}
.wait-item .q-btn > span {
  vertical-align: bottom;
  margin-left: 10px;
  cursor: pointer;
}
.wait-item .q-btn .write-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-position-x: 2px;
  background-position-y: 0px;
  background-image: url("~@/assets/icon/write3.png");
  background-size: 14px 14px;
  background-repeat: no-repeat;
}
.q-has-img {
  display: inline-block;
  width: 16px;
  height: 14px;
  background-image: url("~@/assets/icon/img-icon.png");
  background-repeat: no-repeat;
}
.qs-title {
    display: inline-block;
  width: 90%;
  /* background-color: crimson; */
  word-wrap: break-word;
}
</style>