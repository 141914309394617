<template>
  <div class="question">
    <div v-for="(item, index) in questions" :key="index">
      <div>
        <span class="q-title" @click="TZ(item.id)">{{
          item.questionTitle
        }}</span>
        <span class="Gold_coin" v-if="item.competePriceSetting != 0"></span>
        <span class="plus" v-if="item.competePriceSetting != 0"
          >+{{ item.competePriceSetting }}</span
        >
      </div>
      <div v-if="item.describe != undefined">
        <div class="q-describe" v-show="item.describe.length > 80">
          <div class="q-contentsl" v-show="!isExpand">
            {{ item.describe.substring(0, 80) + "..." }}
          </div>
          <div class="q-content" v-show="isExpand">
            {{ item.describe
            }}<span class="q-expended" @click="expandThised">收起</span>
          </div>
          <span class="q-expend" v-show="!isExpand" @click="expandThis"
            >展开</span
          >
        </div>
      </div>
      <div v-if="item.describe != undefined">
        <div
          class="q-describe"
          v-show="item.describe.length <= 80 && item.describe.length > 0"
        >
          <div class="q-contentsl">{{ item.describe }}</div>
        </div>
      </div>
      <div class="q-img-video">
        <div class="media">
          <!-- v-for="(item,index) in question.mediaList" :key="index" -->
          <span>{{item.img}}</span>
          <img
            v-show="
              item.img&&item.img != APIs && item.img != API && item.img != API + null
            "
            width="100%"
            height="90px"
            :src="item.img"
            alt=""
            @click="clickImg($event)"
            style="margin-bottom:10px"
          />
          <!-- v-if="item.type=='img'" -->
          <!-- 放大图片 -->
          <V-PicturP
            v-if="showImg"
            @clickit="viewImg"
            :imgSrc="imgSrc"
          ></V-PicturP>
          <!-- v-if="item.type=='video'" -->
          <video
            v-if="
              item.video != APIs &&
                item.video != API &&
                item.video != API + null
            "
            :src="item.video"
            controls="controls"
            width="100%"
            height="90px"
            style="border :1px solid rgba(220,220,220,1); object-fit:cover
                 "
          >
            <!-- 您的浏览器不支持 video 标签。  -->
          </video>
        </div>
      </div>
      <div class="q-btns">
        <div class="time" style="margin-bottom:6px">
          <span>问题分类:{{ item.problemClassification }}</span>
          <span class="time" style="margin-left:20px"
            >发布者：{{ item.name }}</span
          >
          <span class="time">{{ item.createTime }}</span>
        </div>

        <!-- <i class="keyword-icon" > </i> -->
        <!-- <span v-show="item.keywords">
              <i class="keyword-icon" > </i>
                <span
              class="word"
              v-for="(word, index) in JSON.parse( item.keywords )" :key="index"
            >
              {{ word }}
            </span>
            </span> -->
      </div>
      <div class="q-btns" style="margin-top:10px">
        <span v-show="item.keywords != null">
          <i class="keyword-icon"> </i>
          <span
            class="title12"
            @click="keyWordSearch(word)"
            v-for="(word, index) in JSON.parse(item.keywords)"
            :key="index"
          >
            <span class="ega">{{ word }}</span>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import PicturP from "@/views/wisdom-answer/answerDetail/hotspot-details/picture-enlargement/index.vue";
export default {
  data() {
    return {
      isExpand: false,
      showImg: false,
      imgSrc: "",
      video: "",
      API: "",
      APIs: "",
    };
  },
  created() {
    this.API = this.api.LoginURL.concat();
    this.APIs = this.api.LoginURL.concat(undefined);
    console.log(this.questions);
  },
  methods: {
    // 跳转
    TZ(id) {
      this.$router.push({
        path: "/writeanswer",
        query: {
          id: id,
        },
      });
    },
    keyWordSearch(word) {
      ////标签搜索
      this.$router.push({
        path: "/automatic",
        query: {
          word: word,
        },
      });
    },
    expandThised() {
      this.isExpand = false;
    },
    expandThis() {
      this.isExpand = true;
    },
    clickImg(e) {
      this.showImg = true;
      // 获取当前图片地址
      this.imgSrc = e.currentTarget.src;
    },
    viewImg() {
      this.showImg = false;
    },
  },
  props: {
    questions: {
      type: Array,
      required: true,
    },
  },
  components: {
    "V-PicturP": PicturP,
  },
};
</script>

<style>
.title12 {
  border: 1px solid #8590a6;
  border-radius: 13px;
  padding-top: 3px;
  padding-left: 3px;
  padding-right: 3px;
  margin-right: 5px;
  color: #8590a6;
  padding-bottom: 6px;
}
.title12:not(:first-of-type) {
  margin-left: 5px;
}
.color-8590A6 {
  color: #8590a6;
}
.title12:hover {
  cursor: pointer;
  background-color: #fd6e05;
  color: rgb(255, 255, 255);
  border: 1px solid #8590a6;
  border-color: white;
}
.question .q-title {
  font-size: 16px;
  font-weight: 600;
  color: rgba(4, 4, 4, 1);
  padding-bottom: 25px;
  margin-right: 10px;
  cursor: pointer;
  /* border: 1px solid goldenrod; */
}
.q-title:hover {
  color: #fd6e05;
}
.question .q-describe {
  position: relative;
  font-size: 14px;
  font-weight: 300;
  color: rgba(4, 4, 4, 1);
  line-height: 24px;
  /* padding-bottom: 20px; */
  min-height: 48px;
  display: inline-block;
  width: 99%;
  /* border: 1px solid red; */
}
.question .q-describe .q-expend {
  position: relative;
  bottom: 15px;
  right: 0;
  font-size: 14px;
  color: #7a8f9a;
  cursor: pointer;
  /* border: 1px solid darkmagenta; */
  width: 100%;
  display: inline-block;
  text-align: right;
}
.q-expended {
  position: absolute;
  bottom: -5px;
  right: 0;
  font-size: 14px;
  color: #7a8f9a;
  cursor: pointer;
  /* border: 1px solid darkmagenta; */
}
.time {
  margin-right: 30px;
}
.q-img-video .media {
  display: inline-block;
  width: 150px;
  /* height: 90px; */
  /* border:1px solid rgba(220,220,220,1); */
  margin-bottom: 10px;
  display: inline-block;
  /* border: 1px solid red; */
}
.q-img-video .media:not(:first-of-type) {
  margin-left: 10px;
  vertical-align: top;
}
.q-contentsl,
.q-content {
  width: 100%;
  /* border: 1px solid springgreen; */
  word-break: break-word;
}
.q-btns {
  position: relative;
  /* border: 1px solid red; */
}
.q-btns * {
  font-size: 12px;
  font-weight: 300;
  color: rgba(118, 118, 118, 1);
  vertical-align: middle;
}
.q-btns .keyword-icon {
  /* margin-left: 20px; */
}
.q-btns .right-btns > span {
  margin-left: 10px;
}
.q-btns .right-btns > span > * {
  vertical-align: middle;
  margin-left: 5px;
}
/* 金币 */
.Gold_coin {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("../../assets/icon/Gold.png");
  background-repeat: no-repeat;
  position: relative;
  /* border: 1px solid red; */
  top: 5px;
  display: inline-block;
}
.plus {
  width: 25px;
  height: 12px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(253, 110, 5, 1);
  line-height: 24px;
  position: relative;
  top: -5px;
}
/* .ega {
  border: 1px solid #8590a6;
  border-radius: 13px;
  padding: 3px;
  margin-right: 5px;
  color: #8590a6;
} */
</style>
