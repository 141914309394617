<template>
  <!-- 引用页 -->
  <div class="Reference" v-loading="loading">
    <!-- 内容 -->
    <div class="Reference_content">
      <!-- 导航 -->
      <div class="close-answer">
        <span class="list"></span>
        <span>其他类似问题</span>
        <!-- 引用按妞 -->
        <span class="icon" @click="close()"></span>
      </div>
      <div>
        <div class="Reference_list">
          <!-- 循环内容 -->
          <div
            class="Reference_center"
            v-show="smilQuestionList.length != 0"
            v-for="item in smilQuestionList"
            :key="item.id"
          >
            <!-- 标题 -->
            <div class="title" v-if="item.title">
              {{ item.title }}
              <span
                class="Gold_coin"
                v-if="item.viewPrice != 0 && item.viewPrice != undefined"
              ></span>
              <span
                class="plus"
                v-if="item.viewPrice != 0 && item.viewPrice != undefined"
                >+{{ item.viewPrice }}</span
              >
            </div>
            <!-- 内容 -->
            <div
              class="content"
              v-if="item.content && item.content.length > 200"
            >
              {{ item.content.substring(0, 200) + "......" }}
            </div>
            <div
              class="content"
              v-else-if="item.content && item.content.length <= 200"
            >
              {{ item.content }}
            </div>
            <!-- 展开 -->
            <div style="margin-top:20px;margin-bottom:10px">
              <span class="open" v-if="pid != item.id" @click="open(item.id)">
                展开答案 <span class="down"></span
              ></span>
              <span class="away" v-if="pid == item.id" @click="away(item.id)">
                收起<span class="up"></span>
              </span>
              <!-- <span class="open" v-if="pid!=item.id" @click="open(item.id)"> 展开答案 <span class="down"></span></span> -->
              <!-- 时间 -->
              <span class="time">{{ item.time }}</span>
            </div>
            <!-- 答案 -->
            <div v-if="pid == item.id">
              <V-Answer :IDS="item.id" @closeYy="closeYy"></V-Answer>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="block" v-if="smilQuestionList.length != 0">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="pagesize"
            layout=" prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
        <div
          v-if="smilQuestionListlength == true"
          style="margin-top:30px;width:100%;line-height: 500px;height:500px;text-align:center;font-size: 20px;color: rgb(153, 153, 153);"
        >
          暂无相似问题
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { dataFormat, formatDate, showTimeFormat } from "@/js/common/index.js";
import Answer from "@/views/wisdom-answer/index/quote/answer/index.vue";
import { DELETE, QUERYED, INSERT, UPDATE, QUERY } from "@/services/dao.js";
import { Alert } from "element-ui";
export default {
  data() {
    return {
      smilQuestionList: [], //相似问题数组
      currentPage: 1, //页数
      pagesize: 50, //条数
      total: 500,
      pid: 0,
      smilQuestionListlength: false,
      loading: false,
      // visable:-1
    };
  },
  props: {},
  created() {
    // this.init();
    //alert(this.$route.query.id)
  },
  components: {
    "V-Answer": Answer,
  },
  mounted() {
    this.$on("bridge", (val) => {
      this.init(val);
    });
  },
  methods: {
    open(id) {
      this.pid = id;
    },
    away(id) {
      this.pid = 0;
    },
    //  关闭引用
    async close() {
      this.$emit("closed");
    },
    async closeYy(id, IDS) {
      this.$emit("closeYy", id, IDS); //引用
    },
    //    引用初始化加载
    async init(val) {
      this.loading = true;
      this.smilQuestionList = []; //清空
      let price = 0;
      let id = this.$route.query.id;
      //通过ID查询查看分成的费用
      let data = await QUERYED(
        "post",
        "",
        "  WDQuestion(where: {id: {_eq: " +
          id +
          "}}) {viewPrice competeDeadlineTime  competePriceSetting   competeMode }"
      );
      if (
        data.data.WDQuestion[0].competeMode == 1 ||
        showTimeFormat(data.data.WDQuestion[0].competeDeadlineTime) <
          showTimeFormat(new Date())
      ) {
        ///悬赏为免费
        price = 0;
      } else if (data.data.WDQuestion[0].competeMode == 2) {
        ///为竞价
        price = val; //输入竞价金额
      } else if (
        data.data.WDQuestion[0].competeMode != 1 &&
        data.data.WDQuestion[0].competeMode != 2
      ) {
        //如果不是免费、不是竞价
        price = data.data.WDQuestion[0].competePriceSetting; //悬赏金额
      }
      // console.log("查看分成价格："+data.data.WDQuestion[0].viewPrice,"悬赏金额"+price,"问题ID"+id,"一页多少条："+this.pagesize,"多少页："+this.currentPage)
      axios({
        method: "post",
        url: this.api.LoginURL.concat("/common/similarQuestion"),
        params: {
          id: id,
          money: data.data.WDQuestion[0].viewPrice,//分成
          raw: this.pagesize,///行数
          page: this.currentPage,///页数
          competePriceSetting: price,//悬赏
        },
      })
        .then((response) => {
          // console.log("相似问题："+JSON.stringify(response.data.data.data))
          if (response.data.data.data.length != 0) {
            for (let i = 0; i < response.data.data.data.length; i++) {
              this.smilQuestionList.push({
                id: response.data.data.data[i].id,
                title: response.data.data.data[i].questionTitle,
                content: response.data.data.data[i].questionContent,
                time: showTimeFormat(response.data.data.data[i].createTime),
                viewPrice: response.data.data.data[i].viewPrice,
                isViewFree: response.data.data.data[i].isViewFree,
              });
            }
            this.total = response.data.data.data[0].numFound;
          } else if (response.data.data.data.length == 0) {
            this.smilQuestionListlength = true;
          }
          console.log("分页" + this.total);
        })
        .catch(function(error) {
          console.log("错误" + error);
          //   this.$message.error('加载失败！请重新刷新！');

          this.$message.success("加载失败,请重新刷新!");
        });
      this.loading = false;
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      // this.pagesize=val;
    },
    handleCurrentChange(val) {
      this.smilQuestionList = []; //清空
      console.log(`当前页: ${val}`);
      this.currentPage = val; //当前页数
      console.log("条数" + this.total);
      this.init();
    },
  },
};
</script>
<style scoped>
@import url(~@/styles/page/index.css);
.Reference {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.Reference_content {
  width: 75%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  margin: 50px auto;
  margin-top: 20px;
  /* margin-bottom: 20px; */
  border-radius: 3%;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
  height: 600px;
  /* overflow-y:hidden; */
  /* overflow: hidden; */
  /* border: 1px solid deepskyblue; */
  /* display: inline-block; */
}
.open {
  /* font-size: 13px; */
  cursor: pointer;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #0084ff;
}
.down {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("../../../../assets/icon/down2.png");
  background-repeat: no-repeat;
  position: relative;
  top: 10px;
  left: 2px;
}
.up {
  /* Up.png */
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("../../../../assets/Up.png");
  background-repeat: no-repeat;
  position: relative;
  top: 4px;
  left: 2px;
}
.icon {
  width: 20px;
  height: 20px;
  text-align: right;
  /* margin-top: 200px; */
  position: relative;
  /* top: 20px;
       left: 30px; */
  background-image: url("~@/assets/icon/close1.png");
  background-repeat: no-repeat;
  /* background-color: rebeccapurple; */
  /* background-position: auto; */
  /* display: inline-block; */
  background-size: 20px 20px;
  /* border: 1px solid red; */
  float: right;
}
.close-answer {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(78, 78, 78, 1);
  position: relative;
  /* right: 10px;
    top: -10px; */
  cursor: pointer;
  width: 100%;
  height: 40px;
  line-height: 40px;
  /* border: 1px solid red; */
  margin-top: 10px;
}
.list {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url("../../../../assets/liebiao.png");
  position: relative;
  top: 7px;
}
.Reference_center {
  /* border: 1px solid red; */
  width: 98%;
  margin: auto;
  border-top: 1.5px dashed #e5e5e5;
  position: relative;
  margin-bottom: 10px;
}
.title {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #000000;
  line-height: 30px;
  word-wrap: break-word;
}
.content {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 100;
  color: #000000;
  line-height: 25px;
  word-wrap: break-word;
}
.time {
  font-size: 13px;
  color: #a4a4a4;
  float: right;
}
.Reference_list {
  width: 100%;
  height: 500px;
  /* border: 1px solid forestgreen; */
  overflow: auto;
  display: inline-block;
  /* margin-bottom: 20px; */
  position: relative;
}
.block {
  width: 100%;
  height: 30px;
  /* border: 1px solid red; */
  /* vertical-align: bottom; */
}
.away {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #686868;
  cursor: pointer;
}
.block {
  width: 100%;
  /* border: 1px solid red; */
}
/* 金币 */
.Gold_coin {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("../../../../assets/icon/Gold.png");
  background-repeat: no-repeat;
  position: relative;
  /* border: 1px solid red; */
  top: 5px;
  display: inline-block;
}
.plus {
  width: 25px;
  height: 12px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(253, 110, 5, 1);
  line-height: 24px;
  position: relative;
  top: -5px;
}
</style>
